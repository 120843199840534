import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';

import { firebaseError } from 'utils';
import firebase from 'firebase.js';

import { checkUserData, AUTH_UPDATE_CATALOGUE_DATA } from './auth';

import {
  fetchCollection,
  fetchDocument,
  createDocument,
  deleteDocument,
  updateDocument,
} from '../api';

export const CATALOGUES_FETCH_DATA_INIT = createAction('CATALOGUES_FETCH_DATA_INIT');
export const CATALOGUES_FETCH_DATA_SUCCESS = createAction(
  'CATALOGUES_FETCH_DATA_SUCCESS'
);
export const CATALOGUES_FETCH_DATA_FAIL = createAction('CATALOGUES_FETCH_DATA_FAIL');

export const CATALOGUES_DELETE_CATALOGUE_INIT = createAction('CATALOGUES_DELETE_CATALOGUE_INIT');
export const CATALOGUES_DELETE_CATALOGUE_SUCCESS = createAction(
  'CATALOGUES_DELETE_CATALOGUE_SUCCESS'
);
export const CATALOGUES_DELETE_CATALOGUE_FAIL = createAction('CATALOGUES_DELETE_CATALOGUE_FAIL');

export const CATALOGUES_CREATE_CATALOGUE_INIT = createAction('CATALOGUES_CREATE_CATALOGUE_INIT');
export const CATALOGUES_CREATE_CATALOGUE_SUCCESS = createAction(
  'CATALOGUES_CREATE_CATALOGUE_SUCCESS'
);
export const CATALOGUES_CREATE_CATALOGUE_FAIL = createAction('CATALOGUES_CREATE_CATALOGUE_FAIL');

export const CATALOGUES_MODIFY_CATALOGUE_INIT = createAction('CATALOGUES_MODIFY_CATALOGUE_INIT');
export const CATALOGUES_MODIFY_CATALOGUE_SUCCESS = createAction(
  'CATALOGUES_MODIFY_CATALOGUE_SUCCESS'
);
export const CATALOGUES_MODIFY_CATALOGUE_FAIL = createAction('CATALOGUES_MODIFY_CATALOGUE_FAIL');

export const CATALOGUES_CLEAN_UP = createAction('CATALOGUES_CLEAN_UP');


export const fetchCatalogues = (catalogueId = '', vendorId = '') => {
  return async (dispatch, getState) => {
    dispatch(checkUserData());

    dispatch(CATALOGUES_FETCH_DATA_INIT());
    if (catalogueId) {
      let catalogue;
      try {
        catalogue = await fetchDocument('catalogues', catalogueId);
        catalogue.from = moment(catalogue.from.toDate()).format('ddd MMM DD YYYY');
        catalogue.to = moment(catalogue.to.toDate()).format('ddd MMM DD YYYY');
        catalogue.createdAt = moment(catalogue.createdAt.toDate()).format('ddd MMM DD YYYY');
      } catch (error) {
        toastr.error(String(error), error);
        return dispatch(CATALOGUES_FETCH_DATA_FAIL({ error }));
      }

      if (!catalogue) {
        const errorMessage = 'Catalogues not available';
        toastr.error(String(errorMessage), errorMessage);
        return dispatch(CATALOGUES_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const catalogues = getState().catalogues.data;
      catalogues.push(catalogue);


      return dispatch(
        CATALOGUES_FETCH_DATA_SUCCESS({
          data: catalogues,
        })
      );
    }

    let catalogues;

    try {
      const vendorRef = firebase.firestore().collection('vendors').doc(vendorId);
      const options = { queries: [{ attribute: "vendorRef", operator: "==", value: vendorRef }] };
      catalogues = await fetchCollection('catalogues', options);
      let copy;
      catalogues.map(catalogueItem => {
        copy = catalogueItem;
        copy.from = moment(copy.from.toDate()).format('ddd MMM DD YYYY');
        copy.to = moment(copy.to.toDate()).format('ddd MMM DD YYYY');
        copy.createdAt = moment(copy.createdAt.toDate()).format('ddd MMM DD YYYY');
        return copy;
      });
      catalogues.allCategories = await fetchCollection('categories');

    } catch (error) {
      toastr.error(String(error), error);
      return dispatch(CATALOGUES_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      CATALOGUES_FETCH_DATA_SUCCESS({
        data: catalogues,
      })
    );
  };
};

const deleteThumbnail = (oldThumbnail) => {
  return firebase.storage().ref(`catalogues/${oldThumbnail}.jpg`).delete();
};


const deletePdf = (oldPdf) => {
  return firebase.storage().ref(`catalogues/${oldPdf}.pdf`).delete();
};

export const deleteCatalogue = (id) => {
  return async (dispatch, getState) => {
    dispatch(CATALOGUES_DELETE_CATALOGUE_INIT());
    const { locale } = getState().preferences;
    const { thumbnailUrl } = getState()
      .catalogues.data.filter((catalogue) => catalogue.id === id)
      .pop();


    const { pdfUrl } = getState()
      .catalogues.data.filter((catalogue) => catalogue.id === id)
      .pop();

    const deleteThumbnailTask = thumbnailUrl ? deleteThumbnail(id) : null;

    const deletePdfTask = pdfUrl ? deletePdf(id) : null;

    const deleteCatalogueTask = deleteDocument('catalogues', id);

    try {
      await Promise.all([deleteThumbnailTask, deletePdfTask, deleteCatalogueTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      console.error('ERROR WHILE DELETEING CATALOGUE', error);
      return dispatch(
        CATALOGUES_DELETE_CATALOGUE_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The catalogue was deleted.');
    return dispatch(CATALOGUES_DELETE_CATALOGUE_SUCCESS({ id }));
  };
};

const uploadPdf = (uid, file) => {
  const storageRef = firebase.storage().ref();

  const fileExtension = file.name.split('.').pop();

  const fileName = `${uid}.${fileExtension}`;

  return storageRef.child(`catalogues/${fileName}`).put(file);
};


const getPdfUrl = (uid, file) => {
  const fileExtension = file.name.split('.').pop();

  const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;

  return `${bucketUrl}/o/catalogues%2F${uid}.${fileExtension}?alt=media`;
};

export const createCatalogue = ({
  title,
  from,
  to,
  filePdf,
  checkedCategories,
  vendorId,
  validity
}) => {
  return async (dispatch, getState) => {
    dispatch(CATALOGUES_CREATE_CATALOGUE_INIT());
    const { locale } = getState().preferences;

    const ref = firebase.firestore().collection('catalogues').doc();
    const uid = ref.id;

    let uploadPdfTask = null;
    let pdfUrl = null;
    if (filePdf) {
      pdfUrl = getPdfUrl(uid, filePdf);
      uploadPdfTask = uploadPdf(uid, filePdf);

    }
    const categories = JSON.parse(checkedCategories);
    const vendorRef = firebase.firestore().collection('vendors').doc(vendorId);
    const catalogueData = {
      title,
      from: moment(from.slice(4)).toDate(),
      to: moment(to.slice(4)).toDate(),
      thumbnailUrl: null,
      pdfUrl,
      createdAt: moment(Date()).toDate(),
      categories,
      vendorRef,
      validity
    };

    const createCataloguesDbTask = createDocument('catalogues', uid, catalogueData);

    try {
      await Promise.all([
        uploadPdfTask,
        createCataloguesDbTask,
      ]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        CATALOGUES_CREATE_CATALOGUE_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Catalogue created successfully');
    return dispatch(CATALOGUES_CREATE_CATALOGUE_SUCCESS({ catalogue: catalogueData }));
  };
};


export const modifyCatalogue = ({
  title,
  from,
  to,
  filePdf,
  checkedCategories,
  id,
  validity
}) => {
  return async (dispatch, getState) => {
    const catalogue = getState().catalogues.data.find((thisCatalogue) => thisCatalogue.id === id);

    const categories = JSON.parse(checkedCategories);


    const { thumbnailUrl } = catalogue;
    const { pdfUrl } = catalogue;
    let deleteThumbnailTask;

    let deletePdfTask;
    let uploadPdfTask;
    let newPdfUrl = null;
    if (filePdf) {
      newPdfUrl = null;
      deleteThumbnailTask = thumbnailUrl && deleteThumbnail(id);
      deletePdfTask = pdfUrl && deletePdf(id);
      uploadPdfTask = uploadPdf(id, filePdf);
    }



    const catalogueData = {
      title,
      from: moment(from.slice(4)).toDate(),
      to: moment(to.slice(4)).toDate(),
      createdAt: moment(Date()).toDate(),
      categories,
      pdfUrl: pdfUrl || newPdfUrl,
      validity
    };
    const updateCatalogueDbTask = updateDocument('catalogues', id, catalogueData);

    try {
      await Promise.all([deleteThumbnailTask, deletePdfTask, uploadPdfTask, updateCatalogueDbTask]);
    } catch (error) {
      toastr.error('', String(error));
      return dispatch(
        CATALOGUES_MODIFY_CATALOGUE_FAIL({
          error: String(error),
        })
      );
    }

    const { uid } = firebase.auth().currentUser;

    if (id === uid) {
      dispatch(AUTH_UPDATE_CATALOGUE_DATA({ ...catalogueData, id }));
    }


    return dispatch(CATALOGUES_MODIFY_CATALOGUE_SUCCESS({ catalogue: catalogueData, id }));
  };
};
export const cataloguesCleanUp = () => (dispatch) => dispatch(CATALOGUES_CLEAN_UP());
