/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

import { bannersCleanUp } from 'state/actions/banners';
import { useFormatMessage } from 'hooks';


import './VendorForm.scss';

const BannerForm = ({  isEditing, banner, onSubmitHandler, schema }) => {

  const [image, setImage] = useState({ preview: "", raw: "" });
  const handleChange = e => {
    // eslint-disable-next-line no-console
    if(e.target.files.length===0){
      setImage({
        preview: null,
        raw: null
      });
    }
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }
  };

  const { loading, success } = useSelector(
    (state) => ({
      loading: state.banners.loading,
      success: state.banners.success,
    }),
    shallowEqual
  );

  

  const dispatch = useDispatch();

  const { register, handleSubmit /** , control  */ , watch, setValue } = useForm({
    defaultValues: { ...banner },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (success) {
      setValue('file', null);
    }
    return () => dispatch(bannersCleanUp());
  }, [dispatch, success, setValue]);


  const pickAnotherFileMessage = useFormatMessage('UserForm.pickAnotherFile');
  const pickFileMessage = useFormatMessage('UserForm.pickFile');

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                Banner Information
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
              <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Title
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          ref={register}
                          name="placeholder"
                        />
                      </div>
                    </div>
                  </div>
                </div>
          
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      URL
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          ref={register}
                          name="url"
                        />
                      </div>
                    </div>
                  </div>
                </div>
  
                {banner.imageUrl ? (
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Banner Image
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field" >
                        <img style={{maxHeight:"200px", marginRight: 0}}  src={banner.imageUrl} alt="Banner" />
                    </div>
                  </div>
                </div>
                ) : ("")}
                <hr />

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Banner Image
                    </label>
                  </div>

                  <div className="field-body">
                    <div className="field">
                      <div className="file has-name">
                        <label className="file-label">
                          <input
                            className="file-input"
                            type="file"
                            onChange={handleChange}
                            name="file"
                            ref={register}
                            accept="image/*"
                            required={!isEditing}
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <i className="mdi mdi-upload" />
                            </span>
                            <span className="file-label">
                              {watch('file') && watch('file').file
                                ? pickAnotherFileMessage
                                : pickFileMessage}
                            </span>
                          </span>
                          <span className="file-name">
                            {watch('file') && watch('file')[0]?.name}
                          </span>
                        </label>
                      </div>
                    </div>
             

                    {image.preview ? (
                      [
                      <div className="field-label is-normal">
                           <label className="label">
                             Preview
                           </label>
                         </div>,
                      <div className="field">
                      <div  style={{ alignItems:"center"}}>
                          <img src={image.preview} alt="preview" style={{maxHeight:"200px",marginRight: "auto", marginLeft:"auto"}} />
                        </div>
                      </div>
                      ]
                      ) : (
                        ""
                        )}
                  </div>
                </div>
                      

                <hr />
                
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>{useFormatMessage('UserForm.submit')}</span>
                          </button>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>

      </div>
    </>
  );
};

BannerForm.propTypes = {
  banner: PropTypes.shape({
    id: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    // url: PropTypes.string.isRequired,
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
};


export default BannerForm;
