import React, { useEffect, useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';

import BannerForm from 'components/BannerForm';
import { createBanner, modifyBanner, fetchBanners } from 'state/actions/banners';
import paths from 'pages/Router/paths';

const schema = yup.object().shape({
  placeholder: yup.string().required(),
  url: yup.string().required(),
});

const Banner = () => {
  const { id } = useParams();

  const isEditing = useMemo(() => !!id, [id]);

  const { success, bannerData, error } = useSelector(
    (state) => ({
      success: state.banners.success,
      bannerData: state.banners.data.find((user) => user.id === id),
      error: state.banners.error,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing) {
      if (!bannerData) {
        dispatch(fetchBanners(id));
      }
    }
  }, [isEditing, id, bannerData, dispatch]);

  const redirect = ((isEditing && error) || success) && (
    <Redirect to={`${paths.BANNERS}`} />
  );

  const editBannerMessage = "Edit Banner";

  const newBannerMessage = "New Banner";

  const onSubmitHandler = (value) => {
    if (isEditing) {
      const newBanner = {
        ...value,
        file: value?.file[0] || null,
        id,
      };

      dispatch(modifyBanner(newBanner));
    } else {
      const newBanner = {
        ...value,
        file: value?.file[0] || null,
      };
      dispatch(createBanner(newBanner));
    }
  };
  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editBannerMessage : newBannerMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !bannerData ? (
          <ClipLoader />
        ) : (
          <BannerForm
            isEditing={isEditing}
            banner={
              isEditing
                ? bannerData
                : {
                    placeholder: '',
                    url: '',
                  }
            }
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
    </>
  );
};

export default Banner;
