import { createReducer } from 'redux-act';


import {
    BANNERS_FETCH_DATA_INIT,
    BANNERS_FETCH_DATA_SUCCESS,
    BANNERS_FETCH_DATA_FAIL,
    BANNERS_CREATE_BANNER_INIT,
    BANNERS_CREATE_BANNER_SUCCESS,
    BANNERS_CREATE_BANNER_FAIL,
    BANNERS_DELETE_BANNER_FAIL,
    BANNERS_DELETE_BANNER_SUCCESS,
    BANNERS_DELETE_BANNER_INIT,
    BANNERS_MODIFY_BANNER_FAIL,
    BANNERS_MODIFY_BANNER_INIT,
    BANNERS_MODIFY_BANNER_SUCCESS,
    BANNERS_CLEAN_UP
  } from 'state/actions/banners';
  
  const initialState = {
    data: [],
    loading: false,
    error: null,
    success: false,
    deleted: false,
  };

  
  export const bannersReducer = createReducer(
    {
      [BANNERS_FETCH_DATA_INIT]: () => ({
        ...initialState,
        loading: true,
      }),
      [BANNERS_FETCH_DATA_SUCCESS]: (state, payload) => ({
        ...state,
        data: payload.data,
        loading: false,
        error: null,
      }),
      [BANNERS_FETCH_DATA_FAIL]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload.error,
      }),
      [BANNERS_CREATE_BANNER_INIT]: (state) => ({
        ...state,
        loading: true,
      }),
      [BANNERS_CREATE_BANNER_SUCCESS]: (state, payload) => ({
        ...state,
        data: state.data.concat(payload.banner),
        loading: false,
        error: null,
        success: true,
      }),
      [BANNERS_CREATE_BANNER_FAIL]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload.error,
      }),
      [BANNERS_DELETE_BANNER_INIT]: (state) => ({
        ...state,
        loading: true,
      }),
      [BANNERS_DELETE_BANNER_SUCCESS]: (state, payload) => ({
        ...state,
        data: state.data.filter((elem) => elem.id !== payload.id),
        loading: false,
        error: null,
        deleted: true,
      }),
      [BANNERS_DELETE_BANNER_FAIL]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload.error,
      }),
      [BANNERS_MODIFY_BANNER_INIT]: (state) => ({
        ...state,
        loading: true,
      }),
      [BANNERS_MODIFY_BANNER_SUCCESS]: (state, payload) => ({
        ...state,
        data: !state.data
          ? []
          : state.data.map((elem) => {
              if (elem.id === payload.id) {
                return {
                  placeholder: payload.banner.placeholder,
                  banner: payload.banner.location,
                  id: payload.id,
                  logoUrl: payload.banner.logoUrl,
                  createdAt: payload.banner.createdAt,
                };
              }
              return elem;
            }),
        loading: false,
        error: null,
        success: true,
      }),
      [BANNERS_MODIFY_BANNER_FAIL]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload.error,
      }),
      [BANNERS_CLEAN_UP]: (state) => ({
        ...state,
        loading: false,
        error: null,
        success: false,
        deleted: false,
      }),
    },
    initialState
  );
  