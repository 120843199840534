export default {
  ROOT: '/',
  LOGIN: '/login',
  USERS: '/users',
  ADD_USER: '/users/new',
  MODIFY_USER: '/users/:id',
  PROFILE: '/profile',
  RESET_PASSWORD: '/recover-password',
  SECTION: '/section',
  SUBMENU_1: '/submenu1',
  SUBMENU_2: '/submenu2',
  VENDORS: '/vendors',
  ADD_VENDOR: '/vendors/new',
  MODIFY_VENDOR: '/vendors/:id',
  ADD_CATALOGUE: '/catalogue/new/:vendorId',
  MODIFY_CATALOGUE: '/catalogue/:id',
  ADD_COUPON: '/coupon/new/:vendorId',
  MODIFY_COUPON: '/coupon/:id',
  ADD_BANNER: '/banner/new',
  MODIFY_BANNER: '/banner/:id',
  BANNERS: '/banners',
  ADD_CATEGORY: '/category/new',
  MODIFY_CATEGORY: '/category/:id',
  CATEGORIES: '/categories',
};
