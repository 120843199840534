import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import Login from 'pages/Login';
import Users from 'pages/Users';
import Vendors from 'pages/Vendors';
import Profile from 'pages/Profile';
import ResetPassword from 'pages/ResetPassword';
import NotFound from 'pages/NotFound';
import User from 'pages/User';
import Vendor from 'pages/Vendor';
import Section from 'pages/Section';
import Catalogue from 'pages/Catalogue';
import Banners from 'pages/Banners';
import Coupon from 'pages/Coupon';
import Banner from 'pages/Banner';
import Categories from 'pages/Categories';
import Category from 'pages/Category';
import paths from './paths';
import PrivateRoute from './PrivateRoute';

const RouterComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={paths.LOGIN} component={Login} />
        <Route exact path={paths.RESET_PASSWORD} component={ResetPassword} />
        <PrivateRoute path={paths.ADD_USER} component={User} />
        <PrivateRoute path={paths.MODIFY_USER} component={User} />
        <PrivateRoute path={paths.USERS} component={Users} />
        <PrivateRoute path={paths.PROFILE} component={Profile} />
        <PrivateRoute path={paths.SECTION} component={Section} />
        <PrivateRoute path={paths.ADD_VENDOR} component={Vendor} />
        <PrivateRoute path={paths.ADD_COUPON} component={Coupon} />
        <PrivateRoute path={paths.MODIFY_COUPON} component={Coupon} />
        <PrivateRoute path={paths.ADD_CATALOGUE} component={Catalogue} />
        <PrivateRoute path={paths.MODIFY_CATALOGUE} component={Catalogue} />
        <PrivateRoute path={paths.BANNERS} component={Banners} />
        <PrivateRoute path={paths.ADD_BANNER} component={Banner} />
        <PrivateRoute path={paths.MODIFY_BANNER} component={Banner} />
        <PrivateRoute path={paths.CATEGORIES} component={Categories} />
        <PrivateRoute path={paths.ADD_CATEGORY} component={Category} />
        <PrivateRoute path={paths.MODIFY_CATEGORY} component={Category} />
        <PrivateRoute path={paths.MODIFY_VENDOR} component={Vendor} />
        <PrivateRoute path={paths.ROOT} component={Vendors} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterComponent;
