/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect , useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { cataloguesCleanUp } from 'state/actions/catalogues';
import { useFormatMessage } from 'hooks';
import DatePicker from 'components/DatePicker';

import './VendorForm.scss';

const CatalogueForm = ({  isEditing, catalogue, onSubmitHandler, schema }) => {
  const { loading, success } = useSelector(
    (state) => ({
      loading: state.catalogues.loading,
      success: state.catalogues.success,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();


  const { register, handleSubmit, control, watch, setValue } = useForm({
    defaultValues: { ...catalogue },
    resolver: yupResolver(schema),
  });
  
  const categoryDataToSend =  catalogue?.allCategories?.map(x => {
    return {
      ...x,
      subcategories:[]
    };
  }) ?? [];
    
  const [categoryDataTest, setCategoryDataTest] = useState(
    categoryDataToSend
   );
   const checkedArray=[]; // is main category checked
   const checkedCategoriesArray=[]; // is subcategory checked

   function getPageNumber(slug){
     if(catalogue?.categories === undefined){
       return undefined;
     }
     console.log('getPageNumber', slug);
     for(let i = 0; i < catalogue.categories.length; i+=1){
       if(catalogue.categories[i].subcategories.length > 0){
         for(let j = 0; j < catalogue.categories[i].subcategories.length; j+=1){
           if(catalogue.categories[i].subcategories[j].slug === slug){
             return catalogue.categories[i].subcategories[j].page;
           }
         }
       }
     }
     return undefined;
   }

   catalogue.allCategories?.forEach(element => { // init all checked to false
    checkedCategoriesArray[element.id] = false;
    for (let index = 0; index <   element.subcategories.length; index+=1) {
      checkedArray[element.subcategories[index].slug] = false;
    }
  });
  const [checkedState, setCheckedState] = useState(
    checkedArray
  );

  const [checkedCategoryState , setCheckedCategoryState ] = useState(
    checkedCategoriesArray
  );

  useEffect(() => {
    if(catalogue.categories!==undefined){
      const updatedCategories = [...catalogue.categories];
      catalogue.allCategories.forEach(element => {
        const ind = updatedCategories.findIndex(item => item.id === element.id);
        if(ind===-1){
          const obj = {...element};
          obj.subcategories = [];
          updatedCategories.push(obj);
        }

      });

      setCategoryDataTest([...updatedCategories]);
    
    }
    catalogue.allCategories.forEach(element => {
      for (let index = 0; index <   element.subcategories.length; index+=1) {
        checkedState[element.subcategories[index].slug] = false;
      }
    });
  
  
    if(catalogue.categories!==undefined){
      catalogue.categories.forEach(element => {
        for (let index = 0; index < element.subcategories.length; index+=1) {
          checkedState[element.subcategories[index].slug]=true;
        }
  
      });
    
    catalogue.allCategories.forEach(element => {
      const category = catalogue.categories.find(x => x.id === element.id);
      if(category!==undefined){
          if(category.subcategories.length===element.subcategories.length){
            checkedCategoriesArray[element.id]=true;
          }else{
            checkedCategoriesArray[element.id]=false;
          }
      }
    });
    setCheckedCategoryState(checkedCategoryState);

  }


  }, []);


const handleOnChangeCategory = (position) => {
  checkedCategoryState[position]= !checkedCategoryState[position];
  Object.keys(checkedState).forEach(key => {
    if(key.substr(0, key.indexOf(':')) === position){
        checkedState[key] = checkedCategoryState[position];
      }
});
if(checkedCategoryState[position]){
  const result = categoryDataTest.map(x => {
    if(x.id ===  position){
      x.subcategories = [...catalogue.allCategories.find(o => o.id === position).subcategories];
    }
    return x;
  });
  setCategoryDataTest(result);

}else{
  const result = categoryDataTest.map(x => {
    /* eslint no-param-reassign: "error" */
    if(x.id ===  position){
      x.subcategories=[];
    }
   return x;
  });
  
  setCategoryDataTest([...result]);

}

setCheckedState(checkedState);
setCheckedCategoryState(checkedCategoryState);
};


const handleOnChange = (position) => {
  checkedState[position]= !checkedState[position]; // associative array [categoryId: string]=checked: bool
  let allCheckedFlag=true;
  Object.keys(checkedState).forEach(key => { // this checks all subcategories if you check the main category
    if(key.substr(0, key.indexOf(':')) === position.substr(0, position.indexOf(':'))){
      if(!checkedState[key]){
         allCheckedFlag=false;
      }
    }
});
  checkedCategoryState[ position.substr(0, position.indexOf(':'))]=allCheckedFlag;
  catalogue.allCategories.forEach(element => {
    const result = element.subcategories.filter(obj => { // result is array of checked category objects
      return obj.slug === position;
    });

    if(result.length !== 0){

      for (let index = 0; index < categoryDataTest.length; index+=1) { // categoryDataTest is data that will be submited
        if(categoryDataTest[index].id === position.substr(0, position.indexOf(':'))){ // find category
          const ind = categoryDataTest[index].subcategories.findIndex(item => item.slug === result[0].slug);

          if (ind !== -1) {
            categoryDataTest[index].subcategories.splice(ind, 1);
          }
          else{
            categoryDataTest[index].subcategories.push(result[0]);
          }
        }
        
      }
    }
  });
  setCategoryDataTest([...categoryDataTest]);
  setCheckedState(checkedState);
  setCheckedCategoryState(checkedCategoryState);


};

const handleOnPageChange = (value, position) => {
  const val = value.target.value;
  console.log('Subcategory page number change', value);
  console.log('categoryDataTest', categoryDataTest); // to je objekt koji se posle
  if(checkedState[position]){
    for (let index = 0; index < categoryDataTest.length; index+=1) { // find category
      if(categoryDataTest[index].id === position.substr(0, position.indexOf(':'))){
        const ind = categoryDataTest[index].subcategories.findIndex(item => item.slug === position); // find subcategory
        if(ind !== -1){ // if this subcategory exists
          categoryDataTest[index].subcategories[ind] = {
            ...categoryDataTest[index].subcategories[ind],
             page: Number(val)
            };
        }
      }
    }
  }
  setCategoryDataTest([...categoryDataTest]);
  console.log('categoryDataTestNew', categoryDataTest);
};

  useEffect(() => {
    if (success) {
      setValue('filePdf', null);
    }
    return () => dispatch(cataloguesCleanUp());
  }, [dispatch, success, setValue]);


  const pickAnotherFileMessage = useFormatMessage('UserForm.pickAnotherFile');
  const pickFileMessage = useFormatMessage('UserForm.pickFile');


  // eslint-disable-next-line no-console
  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                Catalogue Information
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
              <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Title
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          ref={register}
                          name="title"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      From
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <Controller
                        control={control}
                        name="from"
                        render={({ onChange, name, value }) => (
                          <DatePicker
                            name={name}
                            onChange={onChange}
                            date={new Date(value)}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      To
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <Controller
                        control={control}
                        name="to"
                        render={({ onChange, name, value }) => (
                          <DatePicker
                            name={name}
                            onChange={onChange}
                            date={new Date(value)}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Validity
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          ref={register}
                          name="validity"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {catalogue.thumbnailUrl ? (
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Pdf Thumbnail
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field" >
                        <img style={{maxHeight:"250px", marginRight: 0}}  src={catalogue.thumbnailUrl} alt="Banner" />
                    </div>
                  </div>
                </div>
                ) : ("")}
                <hr />
                
              {catalogue.thumbnailUrl1 ? (
                
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                    Change Pdf Thumbnail
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="file has-name">
                        <label className="file-label">
                          <input
                            className="file-input"
                            type="file"
                            name="fileThumbnail"
                            ref={register}
                            accept="image/*"
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <i className="mdi mdi-upload" />
                            </span>
                            <span className="file-label">
                              {watch('file') && watch('file').file
                                ? pickAnotherFileMessage
                                : pickFileMessage}
                            </span>
                          </span>
                          <span className="file-name">
                            {watch('file') && watch('file')[0]?.name}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                
              ):("")}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Pdf File
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="file has-name">
                        <label className="file-label">
                          <input
                            className="file-input"
                            type="file"
                            name="filePdf"
                            ref={register}
                            accept="application/pdf, application/vnd.ms-excel"
                            required={!isEditing}
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <i className="mdi mdi-upload" />
                            </span>
                            <span className="file-label">
                              {watch('filePdf') && watch('filePdf').file
                                ? pickAnotherFileMessage
                                : pickFileMessage}
                            </span>
                          </span>
                          <span className="file-name">
                            {watch('filePdf') && watch('filePdf')[0]?.name}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Categories
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                    <input
                          className="input"
                          type="hidden"
                          ref={register}
                          name="checkedCategories"
                          value= { JSON.stringify(categoryDataTest)}  

                        />
                      {
                         catalogue.allCategories.map((item) => {
                          return (
                            <div key={item.id} className="content" >
                              <span className="title is-6">{item.name} </span>  <input  onChange={() => handleOnChangeCategory(item.id) } checked={checkedCategoryState[item.id]} className="li-checkbox" name={item.id} type ="checkbox"/> 
                              <ul >
                             {
                              item.subcategories.map((subitem) => {
                                if(subitem!== undefined){
                                  return (
                                    <li key={subitem.slug}>
                                    <div className="field is-horizontal">
                                      <div className="field-label is-normal">
                                        <label>
                                        {subitem.name}
                                        </label>
                                      </div>
                                      <div className="field-body">
                                        <div className='field is-narrow'>
                                          <input checked={checkedState[subitem.slug]} className="li-checkbox" name={subitem.slug} onChange={() => handleOnChange(subitem.slug) } type ="checkbox"/>
                                        </div>
                                        <div className="field is-narrow">
                                          <input value={getPageNumber(subitem.slug)} className="input" name={subitem.slug} onChange={(page) => handleOnPageChange(page, subitem.slug)} type ="number" min="0"/>
                                        </div>
                                      </div>
                                    </div>
                                    </li>
                                 );
                                }
                                return '';

                              })
                             }
                             </ul>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                </div>
                <hr />
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>{useFormatMessage('UserForm.submit')}</span>
                          </button>
                        </div>  
                      </div>
                    </div>
                  </div>
                </div>


              </form>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

CatalogueForm.propTypes = {
  catalogue: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    // thumbnailUrl: PropTypes.string,
    pdfUrl: PropTypes.string,
    // createdAt: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired, // PropTypes.instanceOf(Date).isRequired,
    to: PropTypes.string.isRequired, // PropTypes.instanceOf(Date).isRequired,
    validity: PropTypes.string,
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
  // isEditing: PropTypes.bool,
  // isProfile: PropTypes.bool,
};


export default CatalogueForm;
