import { createReducer } from 'redux-act';


import {
    CATALOGUES_FETCH_DATA_INIT,
    CATALOGUES_FETCH_DATA_SUCCESS,
    CATALOGUES_FETCH_DATA_FAIL,
    CATALOGUES_CREATE_CATALOGUE_INIT,
    CATALOGUES_CREATE_CATALOGUE_SUCCESS,
    CATALOGUES_CREATE_CATALOGUE_FAIL,
    CATALOGUES_DELETE_CATALOGUE_FAIL,
    CATALOGUES_DELETE_CATALOGUE_SUCCESS,
    CATALOGUES_DELETE_CATALOGUE_INIT,
    CATALOGUES_MODIFY_CATALOGUE_FAIL,
    CATALOGUES_MODIFY_CATALOGUE_INIT,
    CATALOGUES_MODIFY_CATALOGUE_SUCCESS,
    CATALOGUES_CLEAN_UP
  } from 'state/actions/catalogues';
  
  const initialState = {
    data: [],
    loading: false,
    error: null,
    success: false,
    deleted: false,
  };

  
  export const cataloguesReducer = createReducer(
    {
      [CATALOGUES_FETCH_DATA_INIT]: () => ({
        ...initialState,
        loading: true,
      }),
      [CATALOGUES_FETCH_DATA_SUCCESS]: (state, payload) => ({
        ...state,
        data: payload.data,
        loading: false,
        error: null,
      }),
      [CATALOGUES_FETCH_DATA_FAIL]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload.error,
      }),
      [CATALOGUES_CREATE_CATALOGUE_INIT]: (state) => ({
        ...state,
        loading: true,
      }),
      [CATALOGUES_CREATE_CATALOGUE_SUCCESS]: (state, payload) => ({
        ...state,
        data: state.data.concat(payload.catalogue),
        loading: false,
        error: null,
        success: true,
      }),
      [CATALOGUES_CREATE_CATALOGUE_FAIL]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload.error,
      }),
      [CATALOGUES_DELETE_CATALOGUE_INIT]: (state) => ({
        ...state,
        loading: true,
      }),
      [CATALOGUES_DELETE_CATALOGUE_SUCCESS]: (state, payload) => {
        const temp = state.data.filter((elem) => elem.id !== payload.id);
        temp.allCategories = state.data.allCategories;
        return ({
          ...state,
          data: temp,
          loading: false,
          error: null,
          deleted: true,
        });
      },
      [CATALOGUES_DELETE_CATALOGUE_FAIL]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload.error,
      }),
      [CATALOGUES_MODIFY_CATALOGUE_INIT]: (state) => ({
        ...state,
        loading: true,
      }),
      [CATALOGUES_MODIFY_CATALOGUE_SUCCESS]: (state, payload) => ({
        ...state,
        data: !state.data
          ? []
          : state.data.map((elem) => {
              if (elem.id === payload.id) {
                return {
                  title: payload.catalogue.title,
                  id: payload.id,
                  pdfUrl: payload.catalogue.pdfUrl,
                  thumbnailUrl: payload.catalogue.thumbnailUrl,
                  from: payload.catalogue.from,
                  to: payload.catalogue.to,
                  createdAt: payload.catalogue.createdAt,
                };
              }
              return elem;
            }),
        loading: false,
        error: null,
        success: true,
      }),
      [CATALOGUES_MODIFY_CATALOGUE_FAIL]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload.error,
      }),
      [CATALOGUES_CLEAN_UP]: (state) => ({
        ...state,
        loading: false,
        error: null,
        success: false,
        deleted: false,
      }),
    },
    initialState
  );
  