import React, { useEffect, useMemo } from 'react';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';

import CouponForm from 'components/CouponForm';
import { createCoupon, modifyCoupon, fetchCoupons } from 'state/actions/coupons';
import paths from 'pages/Router/paths';

const schema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string().required(),
  terms: yup.string().notRequired(),
  badge: yup.string().notRequired(),
  code: yup.string().notRequired(),
  qrcode: yup.string().notRequired(),
  link: yup.string().notRequired(),
  linkLabel: yup.string().notRequired(),
  textcode: yup.string().notRequired(),
  from: yup.string().required(),
  to: yup.string().required(),
  codeDescription: yup.string().notRequired(),
  // displayAs: yup.array().of(yup.string()).notRequired(),
  isDiscountCode: yup.boolean().notRequired(),
  couponBanner: yup.string().notRequired(),
  validity: yup.string().notRequired(),
});

const Coupon = () => {
  const { id } = useParams();
  const { vendorId } = useParams();

  const isEditing = useMemo(() => !!id, [id]);
  const history = useHistory();

  const { success, couponData, error } = useSelector(
    (state) => ({
      success: state.coupons.success,
      couponData: state.coupons.data.find((user) => user.id === id),
      error: state.coupons.error,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing) {
      if (!couponData) {
        dispatch(fetchCoupons(id));
      }
    }
  }, [isEditing, id, couponData, dispatch]);

  const redirect = ((isEditing && error) || success) && (
    <Redirect to={`${paths.VENDORS}/${history.location.state.vendorId}`} />
  );

  const editCouponMessage = "Edit Coupon";

  const newCouponMessage = "New Coupon";

  const onSubmitHandler = (value) => {

    if (isEditing) {
      const newCoupon = {
        ...value,
        file: value?.file ? value?.file[0] : null,
        id,
      };
      dispatch(modifyCoupon(newCoupon));
    } else {
      const newCoupon = {
        ...value,
        file: value?.file ? value?.file[0] : null,
        vendorId,
      };
      dispatch(createCoupon(newCoupon));
    }
  };

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editCouponMessage : newCouponMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !couponData ? (
          <ClipLoader />
        ) : (
          <CouponForm
            isEditing={isEditing}
            coupon={
              isEditing
                ? {
                  ...couponData,
                  code: couponData.code ?? '',
                  qrcode: couponData.qrcode ?? '',
                  link: couponData.link ?? '',
                  textcode: couponData.textcode ?? '',
                }
                : {
                  title: '',
                  description: '',
                  terms: '',
                  badge: '',
                  code: '',
                  qrcode: '',
                  link: '',
                  textcode: '',
                  validity: '',
                  from: new Date().toDateString(),
                  to: new Date().toDateString(),
                  // createdAt:  new Date().toDateString(),
                  vendorId
                }
            }
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
    </>
  );
};

export default Coupon;
