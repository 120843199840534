import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import firebase from 'firebase.js';
import moment from 'moment';

import { checkUserData, AUTH_UPDATE_CATEGORY_DATA } from './auth';

import {
  fetchCollection,
  fetchDocument,
  createDocument,
  deleteDocument,
  updateDocument,
} from '../api';

export const CATEGORIES_FETCH_DATA_INIT = createAction('CATEGORIES_FETCH_DATA_INIT');
export const CATEGORIES_FETCH_DATA_SUCCESS = createAction(
  'CATEGORIES_FETCH_DATA_SUCCESS'
);
export const CATEGORIES_FETCH_DATA_FAIL = createAction('CATEGORIES_FETCH_DATA_FAIL');

export const CATEGORIES_DELETE_CATEGORY_INIT = createAction('CATEGORIES_DELETE_CATEGORY_INIT');
export const CATEGORIES_DELETE_CATEGORY_SUCCESS = createAction(
  'CATEGORIES_DELETE_CATEGORY_SUCCESS'
);
export const CATEGORIES_DELETE_CATEGORY_FAIL = createAction('CATEGORIES_DELETE_CATEGORY_FAIL');

export const CATEGORIES_CREATE_CATEGORY_INIT = createAction('CATEGORIES_CREATE_CATEGORY_INIT');
export const CATEGORIES_CREATE_CATEGORY_SUCCESS = createAction(
  'CATEGORIES_CREATE_CATEGORY_SUCCESS'
);
export const CATEGORIES_CREATE_CATEGORY_FAIL = createAction('CATEGORIES_CREATE_CATEGORY_FAIL');

export const CATEGORIES_MODIFY_CATEGORY_INIT = createAction('CATEGORIES_MODIFY_CATEGORY_INIT');
export const CATEGORIES_MODIFY_CATEGORY_SUCCESS = createAction(
  'CATEGORIES_MODIFY_CATEGORY_SUCCESS'
);
export const CATEGORIES_MODIFY_CATEGORY_FAIL = createAction('CATEGORIES_MODIFY_CATEGORY_FAIL');

export const CATEGORIES_CLEAN_UP = createAction('CATEGORIES_CLEAN_UP');


export const fetchCategories = (categoryId = '') => {
  return async (dispatch, getState) => {
    dispatch(checkUserData());

    dispatch(CATEGORIES_FETCH_DATA_INIT());
    if (categoryId) {
      let category;
      try {
        category = await fetchDocument('categories', categoryId);
        category.createdAt = moment( category.createdAt.toDate()).format('ddd MMM DD YYYY');

      } catch (error) {
        toastr.error(String(error), error);
        return dispatch(CATEGORIES_FETCH_DATA_FAIL({ error }));
      }

      if (!category) {
        const errorMessage = 'Categories not available';
        toastr.error(String(errorMessage), errorMessage);
        return dispatch(CATEGORIES_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const categories = getState().categories.data;

      categories.push(category);
      
      return dispatch(
        CATEGORIES_FETCH_DATA_SUCCESS({
          data: categories,
        })
      );
    }

    const { id } = getState().auth.userData;
    let categories;

    try {
      categories = await fetchCollection('categories');
      let copy;
      categories.map(categoryItem => {
        copy =  categoryItem;
        copy.createdAt = moment( copy.createdAt.toDate()).format('ddd MMM DD YYYY');
        return copy;
      });
      
    } catch (error) {
      toastr.error(String(error), error);
      return dispatch(CATEGORIES_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      CATEGORIES_FETCH_DATA_SUCCESS({
        data: categories.filter((category) => category.id !== id),
      })
    );
  };
};


export const deleteCategory = (id) => {
  return async (dispatch, getState) => {
    dispatch(CATEGORIES_DELETE_CATEGORY_INIT());
    const { locale } = getState().preferences;


    const deleteCategoryTask = deleteDocument('categories', id);

    try {
      await Promise.all([deleteCategoryTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        CATEGORIES_DELETE_CATEGORY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The category was deleted.');
    return dispatch(CATEGORIES_DELETE_CATEGORY_SUCCESS({ id }));
  };
};


export const createCategory = ({
  name,
  subcategories,
}) => {
  return async (dispatch, getState) => {
    dispatch(CATEGORIES_CREATE_CATEGORY_INIT());
    const { locale } = getState().preferences;

    const ref =  firebase.firestore().collection('categories').doc();
    const uid = ref.id;

    const subcategoriesArr=JSON.parse(subcategories);
    /* eslint no-param-reassign: "error" */
    subcategoriesArr.map(object => { 
        delete object.errors; 
        return object; 
    }); 

        let slugName = "";
        subcategoriesArr.forEach(element => {
            slugName = element.name.replace(/[^0-9a-z]/gi, '').toLowerCase();
            element.slug = `${uid}:${slugName}:${Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000}`;
        });
    
    
    const categoryData = { name, subcategories:subcategoriesArr,  createdAt:  moment(Date()).toDate(), };

    const createCategoriesDbTask = createDocument('categories', uid, categoryData);
    
    try {
      await Promise.all([
        createCategoriesDbTask,
      ]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        CATEGORIES_CREATE_CATEGORY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Category created successfully');
    return dispatch(CATEGORIES_CREATE_CATEGORY_SUCCESS({ category: categoryData}));
  };
};


export const modifyCategory= ({
    name,
    subcategories,
    id,
}) => {
  return async (dispatch ) => {
    dispatch(CATEGORIES_MODIFY_CATEGORY_INIT());
    let category;
    try {
      category = await fetchDocument('categories', id);
      
    } catch (error) {
      toastr.error(String(error), error);
      return dispatch(CATEGORIES_FETCH_DATA_FAIL({ error }));
    }
    // let i = 0;
    const subcategoriesArr=JSON.parse(subcategories);
    let slugName = "";

        /* eslint no-param-reassign: "error" */
        subcategoriesArr.map((object, index) => { 
            delete object.errors; 
            if(category.subcategories[index] !== undefined){
              object.slug = category.subcategories[index].slug;

            }else{
              slugName = object.name.replace(/[^0-9a-z]/gi, '').toLowerCase();
              object.slug = `${id}:${slugName}:${Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000}`;
            }
            return object; 
        }); 

        const categoryData = {
            name,
            subcategories: subcategoriesArr,
            createdAt: moment(Date()).toDate(),
        };
        const updateCategoryDbTask = updateDocument('categories', id, categoryData);

    try {
      await Promise.all([updateCategoryDbTask]);
    } catch (error) {
      toastr.error('', String(error));
      return dispatch(
        CATEGORIES_MODIFY_CATEGORY_FAIL({
          error: String(error),
        })
      );
    }

    const { uid } = firebase.auth().currentUser;

    if (id === uid) {
      dispatch(AUTH_UPDATE_CATEGORY_DATA({ ...categoryData, id }));
    }


    return dispatch(CATEGORIES_MODIFY_CATEGORY_SUCCESS({ category: categoryData, id }));
  };
};
export const categoriesCleanUp = () => (dispatch) => dispatch(CATEGORIES_CLEAN_UP());
