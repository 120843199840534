import { createReducer } from 'redux-act';


import {
    VENDORS_FETCH_DATA_INIT,
    VENDORS_FETCH_DATA_SUCCESS,
    VENDORS_FETCH_DATA_FAIL,
    VENDORS_CREATE_VENDOR_INIT,
    VENDORS_CREATE_VENDOR_SUCCESS,
    VENDORS_CREATE_VENDOR_FAIL,
    VENDORS_DELETE_VENDOR_FAIL,
    VENDORS_DELETE_VENDOR_SUCCESS,
    VENDORS_DELETE_VENDOR_INIT,
    VENDORS_MODIFY_VENDOR_FAIL,
    VENDORS_MODIFY_VENDOR_INIT,
    VENDORS_MODIFY_VENDOR_SUCCESS,
    VENDORS_CLEAN_UP
  } from 'state/actions/vendors';
  
  const initialState = {
    data: [],
    loading: false,
    error: null,
    success: false,
    deleted: false,
  };

  
  export const vendorsReducer = createReducer(
    {
      [VENDORS_FETCH_DATA_INIT]: () => ({
        ...initialState,
        loading: true,
      }),
      [VENDORS_FETCH_DATA_SUCCESS]: (state, payload) => ({
        ...state,
        data: payload.data,
        loading: false,
        error: null,
      }),
      [VENDORS_FETCH_DATA_FAIL]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload.error,
      }),
      [VENDORS_CREATE_VENDOR_INIT]: (state) => ({
        ...state,
        loading: true,
      }),
      [VENDORS_CREATE_VENDOR_SUCCESS]: (state, payload) => ({
        ...state,
        data: state.data.concat(payload.vendor),
        loading: false,
        error: null,
        success: true,
      }),
      [VENDORS_CREATE_VENDOR_FAIL]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload.error,
      }),
      [VENDORS_DELETE_VENDOR_INIT]: (state) => ({
        ...state,
        loading: true,
      }),
      [VENDORS_DELETE_VENDOR_SUCCESS]: (state, payload) => ({
        ...state,
        data: state.data.filter((elem) => elem.id !== payload.id),
        loading: false,
        error: null,
        deleted: true,
      }),
      [VENDORS_DELETE_VENDOR_FAIL]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload.error,
      }),
      [VENDORS_MODIFY_VENDOR_INIT]: (state) => ({
        ...state,
        loading: true,
      }),
      [VENDORS_MODIFY_VENDOR_SUCCESS]: (state, payload) => ({
        ...state,
        data: !state.data
          ? []
          : state.data.map((elem) => {
              if (elem.id === payload.id) {
                return {
                  name: payload.vendor.name,
                  card: payload.vendor.location,
                  id: payload.id,
                  logoUrl: payload.vendor.logoUrl,
                  logoUrlLandscape: payload.vendor.logoUrlLandscape,
                  loyaltyCard: payload.vendor.loyaltyCard,
                  priority: payload.vendor.priority,
                  createdAt: payload.vendor.createdAt,
                };
              }
              return elem;
            }),
        loading: false,
        error: null,
        success: true,
      }),
      [VENDORS_MODIFY_VENDOR_FAIL]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload.error,
      }),
      [VENDORS_CLEAN_UP]: (state) => ({
        ...state,
        loading: false,
        error: null,
        success: false,
        deleted: false,
      }),
    },
    initialState
  );
  