/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { couponsCleanUp, deleteCouponBanner } from 'state/actions/coupons';
import { useFormatMessage } from 'hooks';
import DatePicker from 'components/DatePicker';

import './VendorForm.scss';

const CouponForm = ({ coupon, onSubmitHandler, schema }) => {
  const { loading, success } = useSelector(
    (state) => ({
      loading: state.coupons.loading,
      success: state.coupons.success,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const couponDefault = {
    ...coupon,
    days: JSON.stringify(coupon.days),
    displayedAs: JSON.stringify(coupon.displayedAs),
  };
  const { register, handleSubmit, control, setValue, watch } = useForm({
    defaultValues: { ...couponDefault },
    resolver: yupResolver(schema),
  });

  const [image, setImage] = useState({ preview: "", raw: "" });

  const handleChange = e => {
    // eslint-disable-next-line no-console
    if(e.target.files.length===0){
      setImage({
        preview: null,
        raw: null
      });
    }
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }
  };

  const onRemoveButtonClickHandler = (banner) => {
    dispatch(deleteCouponBanner({
      banner,
      id: coupon.id,
      title: coupon.title,
      description: coupon.description,
      from: coupon.from,
      to: coupon.to,
    }));
  };

  const pickAnotherFileMessage = useFormatMessage('UserForm.pickAnotherFile');
  const pickFileMessage = useFormatMessage('UserForm.pickFile');

  useEffect(() => {
    return () => dispatch(couponsCleanUp());
  }, [dispatch, success, setValue]);

  let daysArray;
  let displayAsArray;
  let isDiscountCode;

  if (coupon.days != null) {
    daysArray = coupon.days;
  } else {
    daysArray = [true, true, true, true, true, true, true];
  }

  if (coupon.displayedAs != null) {
    displayAsArray = coupon.displayedAs;
  } else {
    displayAsArray = [true, false, false, false];
  }

  if(coupon?.isDiscountCode) {
    isDiscountCode = coupon.isDiscountCode;
  }
  else {
    isDiscountCode = false;
  }
  

  const [days, setDays] = useState(daysArray);
  const [displayTypes, setDisplayTypes] = useState(displayAsArray);
  const [isDiscountCodeState, setIsDiscountCode] = useState(isDiscountCode);

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                Coupon Information
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="field has-check is-horizontal">
                  <div className="field-label">
                    <label className="label">Discount code</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="field">
                        <div className="control">
                          <label className="b-checkbox checkbox">
                          <input
                            className="input"
                            type="hidden"
                            ref={register}
                            name="isDiscountCode"
                            value={isDiscountCodeState}
                          />
                            <input
                              type="checkbox"
                              name="0"
                              ref={register}
                              onChange={e => { setIsDiscountCode(e.target.checked); }}
                              checked={isDiscountCodeState}
                            />
                            <span className="check is-primary" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Title
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          ref={register}
                          name="title"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Description
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          ref={register}
                          name="description"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Validity
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          ref={register}
                          name="validity"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Text above the code
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          ref={register}
                          name="codeDescription"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Terms of services
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="textarea"
                          type="text"
                          ref={register}
                          name="terms"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field has-check is-horizontal">
                  <div className="field-label">
                    <label className="label"> Displayed as </label>
                  </div>
                  <input
                    className="input"
                    type="hidden"
                    ref={register}
                    name="displayedAs"
                    value={JSON.stringify(displayTypes)}
                  />
                  <div className="field-body">
                    <div className="field">
                      <div className="field">
                        <div className="control">
                          <label className="b-checkbox checkbox">
                            Barcode
                            <input
                              type="checkbox"
                              name="0"
                              ref={register}
                              onChange={e => { displayTypes[0] = e.target.checked; setDisplayTypes([...displayTypes]); }}
                              checked={displayTypes[0]}
                            />

                            <span className="check is-primary" />
                          </label>
                          <label className="b-checkbox checkbox">
                            QR code
                            <input
                              type="checkbox"
                              name="1"
                              ref={register}
                              onChange={e => { displayTypes[1] = e.target.checked; setDisplayTypes([...displayTypes]); }}
                              checked={displayTypes[1]}
                            />

                            <span className="check is-primary" />
                          </label>
                          <label className="b-checkbox checkbox">
                            Link
                            <input
                              type="checkbox"
                              name="2"
                              ref={register}
                              onChange={e => { displayTypes[2] = e.target.checked; setDisplayTypes([...displayTypes]); }}
                              checked={displayTypes[2]}
                            />
                            <span className="check is-primary" />
                          </label>
                          <label className="b-checkbox checkbox">
                            Plain text
                            <input
                              type="checkbox"
                              name="3"
                              ref={register}
                              checked={displayTypes[3]}
                              onChange={e => { displayTypes[3] = e.target.checked; setDisplayTypes([...displayTypes]); }}
                            />
                            <span className="check is-primary" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {displayTypes[0] && <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Barcode
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          ref={register}
                          name="code"
                        />
                      </div>
                    </div>
                  </div>
                </div>}
                {displayTypes[1] && <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      QR code
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          defaultValue = ''
                          className="input"
                          type="text"
                          ref={register}
                          name="qrcode"
                        />
                      </div>
                    </div>
                  </div>
                </div>}
                {displayTypes[2] && 
                [
                  <div className="field is-horizontal" key="link">
                    <div className="field-label is-normal">
                      <label className="label">
                        Link
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            defaultValue = ''
                            className="input"
                            type="url"
                            ref={register}
                            name="link"
                          />
                        </div>
                      </div>
                    </div>
                  </div>,
                  <div className="field is-horizontal" key="label">
                    <div className="field-label is-normal">
                      <label className="label">
                        Link button label
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            defaultValue = ''
                            className="input"
                            type="text"
                            ref={register}
                            name="linkLabel"
                          />
                        </div>
                      </div>
                    </div>
                  </div>]}
                {displayTypes[3] && <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Text code
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          defaultValue = ''
                          className="input"
                          type="text"
                          ref={register}
                          name="textcode"
                        />
                      </div>
                    </div>
                  </div>
                </div>}

                {!isDiscountCodeState &&
                <div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Badge Title
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          ref={register}
                          name="badge"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Badge Subtitle
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          ref={register}
                          name="badgeSub"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field has-check is-horizontal">
                  <div className="field-label">
                    <label className="label"> Days when Active </label>
                  </div>
                  <input
                    className="input"
                    type="hidden"
                    ref={register}
                    name="days"
                    value={JSON.stringify(days)} // 

                  />
                  <div className="field-body">
                    <div className="field">
                      <div className="field">
                        <div className="control">
                          <label className="b-checkbox checkbox">
                            MON
                            <input
                              type="checkbox"
                              name="0"
                              ref={register}
                              onChange={e => { days[0] = e.target.checked; setDays([...days]); }}
                              checked={days[0]}
                            />

                            <span className="check is-primary" />
                          </label>
                          <label className="b-checkbox checkbox">
                            TUE
                            <input
                              type="checkbox"
                              name="1"
                              ref={register}
                              onChange={e => { days[1] = e.target.checked; setDays([...days]); }}
                              checked={days[1]}
                            />
                            <span className="check is-primary" />
                          </label>
                          <label className="b-checkbox checkbox">
                            WED
                            <input
                              type="checkbox"
                              name="2"
                              ref={register}
                              checked={days[2]}
                              onChange={e => { days[2] = e.target.checked; setDays([...days]); }}
                            />
                            <span className="check is-primary" />
                          </label>
                          <label className="b-checkbox checkbox">
                            THU
                            <input
                              type="checkbox"
                              name="3"
                              checked={days[3]}
                              ref={register}
                              onChange={e => { days[3] = e.target.checked; setDays([...days]); }}
                            />
                            <span className="check is-primary" />
                          </label>
                          <label className="b-checkbox checkbox">
                            FRI
                            <input
                              type="checkbox"
                              name="4"
                              ref={register}
                              onChange={e => { days[4] = e.target.checked; setDays([...days]); }}
                              checked={days[4]}

                            />
                            <span className="check is-primary" />
                          </label>
                          <label className="b-checkbox checkbox">
                            SAT
                            <input
                              type="checkbox"
                              name="5"
                              ref={register}
                              onChange={e => { days[5] = e.target.checked; setDays([...days]); }}
                              checked={days[5]}

                            />
                            <span className="check is-primary" />
                          </label>
                          <label className="b-checkbox checkbox">
                            SUN
                            <input
                              type="checkbox"
                              name="6"
                              ref={register}
                              onChange={e => { days[6] = e.target.checked; setDays([...days]); }}
                              checked={days[6]}
                            />
                            <span className="check is-primary" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      From
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <Controller
                        control={control}
                        name="from"
                        render={({ onChange, name, value }) => (
                          <DatePicker
                            name={name}
                            onChange={onChange}
                            date={new Date(value)}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      To
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <Controller
                        control={control}
                        name="to"
                        render={({ onChange, name, value }) => (
                          <DatePicker
                            name={name}
                            onChange={onChange}
                            date={new Date(value)}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                {coupon.couponBanner ? (
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Banner Image
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field" >
                        <img style={{maxHeight:"200px", marginRight: 0}}  src={coupon.couponBanner} alt="banner" />
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="button is-small is-danger"
                      onClick={() => onRemoveButtonClickHandler(coupon.couponBanner)}
                    >
                      <span className="icon is-small">
                        <i className="mdi mdi-trash-can" />
                      </span>
                    </button>
                  </div>
                </div>
                ) : ("")}
                {isDiscountCodeState &&
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Banner Image
                    </label>
                  </div>

                  <div className="field-body">
                    <div className="field">
                      <div className="file has-name">
                        <label className="file-label">
                          <input
                            className="file-input"
                            type="file"
                            onChange={handleChange}
                            name="file"
                            ref={register}
                            accept="image/*"
                            required={false}
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <i className="mdi mdi-upload" />
                            </span>
                            <span className="file-label">
                              {watch('file') && watch('file').file
                                ? pickAnotherFileMessage
                                : pickFileMessage}
                            </span>
                          </span>
                          <span className="file-name">
                            {watch('file') && watch('file')[0]?.name}
                          </span>
                        </label>
                      </div>
                    </div>
                    {image.preview ? (
                    <div className="field-body">
                      <div className="field-label is-normal">
                            <label className="label">
                              Preview
                            </label>
                          </div>
                      <div className="field">
                      <div  style={{ alignItems:"center"}}>
                          <img src={image.preview}  alt="preview" style={{maxHeight:"200px",marginRight: "auto", marginLeft:"auto"}} />
                        </div>
                      </div>
                      </div>
                      ) : ("")}
                  </div>
                </div>}
                <hr />
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${loading && 'is-loading'
                              }`}
                          >
                            <span>{useFormatMessage('UserForm.submit')}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

CouponForm.propTypes = {
  coupon: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    // description: PropTypes.string.isRequired,
    terms: PropTypes.string,
    badge: PropTypes.string,
    code: PropTypes.string,
    qrcode: PropTypes.string,
    link: PropTypes.string,
    linkLabel: PropTypes.string,
    textcode: PropTypes.string,
    codeDescription: PropTypes.string,
    badgeSub: PropTypes.string,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    // days: PropTypes.string,
    validity: PropTypes.string,
    couponBanner: PropTypes.string,
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
};



export default CouponForm;
