/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm/**  Controller */ } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

import { vendorsCleanUp } from 'state/actions/vendors';
import {useFormatMessage } from 'hooks';
import { fetchCategories, categoriesCleanUp } from 'state/actions/vendorCategories';

import './VendorForm.scss';

const VendorForm = ({ isEditing, vendor, onSubmitHandler, schema }) => {
  const { loading, success } = useSelector(
    (state) => ({
      loading: state.vendors.loading,
      success: state.vendors.success,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: { ...vendor, },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (success) {
      setValue('file', null);
      setValue('file1', null);
    }
    return () => dispatch(vendorsCleanUp());
  }, [dispatch, success, setValue]);
  useEffect(() => {
      dispatch(fetchCategories());
    return () => dispatch(categoriesCleanUp());
  }, []);

  const { vendorCategories } = useSelector((state) => ({
    vendorCategories: state.vendorCategories.data.sort((a,b) => {
       if(a.priority < b.priority) {return 1;}
       if(a.priority > b.priority){return -1;}
        return 0;
      }),
  }));

  // console.log('Vendor categories', vendorCategories);
  const [image, setImage] = useState({ preview: "", raw: "" });

  const [image1, setImage1] = useState({ preview: "", raw: "" });

  const [vendorCategory, setCategoryState] = useState({
      name: vendor.vendorCategory?.name ?? vendorCategories[0]?.name,
      priority: vendor.vendorCategory?.priority ?? vendorCategories[0]?.priority,
      id: vendor.vendorCategory?.id ?? vendorCategories[0]?.id
  });


  const handleChange = e => {
    if(e.target.files.length===0){
      setImage({
        preview: null,
        raw: null
      });
    }
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }
  };

  const handleChange1 = e => {
    if(e.target.files.length===0){
      setImage1({
        preview: null,
        raw: null
      });
    }
    if (e.target.files.length) {
      setImage1({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }
  };

  const handleCategoryChange = e => {
    console.log('Category is:',e.target.value);
    // selectedCategory = e.target.value;
    const cat = vendorCategories.find((category) => category.id === e.target.value);
    if(cat !== undefined && cat !== null){
      setCategoryState({
          name: cat.name,
          priority: cat.priority,
          id: cat.id
      });
      console.log('New category state',vendorCategory);
    }
  };

  const pickAnotherFileMessage = useFormatMessage('UserForm.pickAnotherFile');
  const pickFileMessage = useFormatMessage('UserForm.pickFile');

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                Vendor Information
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
              <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Name
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          ref={register}
                          name="name"
                        />
                      </div>
                    </div>
                  </div>
                </div>


<div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Card
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          ref={register}
                          name="card"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Priority
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="number"
                          ref={register}
                          name="priority"
                          min="1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Vendor category
                    </label>
                  </div>

                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <span className="select">
                          <input name="vendorCategory" type="hidden" ref={register} value={JSON.stringify(vendorCategory)}/>
                          <select value={vendorCategory?.id}
                            onChange={handleCategoryChange}>
                            {vendorCategories?.map((category) => {
                              if(category !== undefined){
                              return (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>);
                              }
                              return '';
                            })}
                          </select>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field has-check is-horizontal">
                    <div className="field-label">
                      <label className="label"> Loyalty Card </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="field">
                          <div className="control">
                            <label className="b-checkbox checkbox">
                              <input
                                type="checkbox"
                                name="loyaltyCard"
                                ref={register}
                              />
                              <span className="check is-primary" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                {vendor.logoUrl ? (
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Banner Image
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field" >
                        <img style={{maxHeight:"200px", marginRight: 0}}  src={vendor.logoUrl} alt="logo" />
                    </div>
                  </div>
                </div>
                ) : ("")}

                {vendor.logoUrlLandscape ? (
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                     Landscape Banner Image
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field" >
                        <img style={{maxHeight:"200px", marginRight: 0}}  src={vendor.logoUrlLandscape} alt="logo" />
                    </div>
                  </div>
                </div>
                ) : ("")}
                <hr />

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Banner Image
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="file has-name">
                        <label className="file-label">
                          <input
                            className="file-input"
                            onChange={handleChange}
                            type="file"
                            name="file"
                            ref={register}
                            accept="image/*"
                            required={!isEditing}
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <i className="mdi mdi-upload" />
                            </span>
                            <span className="file-label">
                              {watch('file') && watch('file').file
                                ? pickAnotherFileMessage
                                : pickFileMessage}
                            </span>
                          </span>
                          <span className="file-name">
                            {watch('file') && watch('file')[0]?.name}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  {image.preview ? (
                   
                        <div className="field-body">

                      <div className="field-label is-normal">
                           <label className="label">
                             Preview
                           </label>
                         </div>
                      <div className="field">
                      <div  style={{ alignItems:"center"}}>
                          <img src={image.preview}  alt="preview" style={{maxHeight:"200px",marginRight: "auto", marginLeft:"auto"}} />
                        </div>
                      </div>
                      </div>

                      
                      ) : (
                        ""
                        )}
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Landscape Banner Image
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="file has-name">
                        <label className="file-label">
                          <input
                            className="file-input"
                            onChange={handleChange1}
                            type="file"
                            name="file1"
                            ref={register}
                            accept="image/*"
                            // required={!isEditing}
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <i className="mdi mdi-upload" />
                            </span>
                            <span className="file-label">
                              {watch('file1') && watch('file1').file
                                ? pickAnotherFileMessage
                                : pickFileMessage}
                            </span>
                          </span>
                          <span className="file-name">
                            {watch('file1') && watch('file1')[0]?.name}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  {image1.preview ? (
                        <div className="field-body">
                      <div className="field-label is-normal">
                           <label className="label">
                             Preview
                           </label>
                         </div>
                      <div className="field">
                      <div  style={{ alignItems:"center"}}>
                          <img src={image1.preview} alt="preview"
                           style={{maxHeight:"200px",marginRight: "auto", marginLeft:"auto"}} />
                        </div>
                      </div>
                      </div>
                      ) : (
                        ""
                        )}
                </div>
                <hr />
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>{useFormatMessage('UserForm.submit')}</span>
                          </button>
                        </div>                 
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

VendorForm.propTypes = {
  vendor: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    logoUrl: PropTypes.string,
    logoUrlLandscape: PropTypes.string,
    card: PropTypes.string,
    loyaltyCard: PropTypes.bool,
    priority: PropTypes.number,
    /* vendorCategory: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      priority: PropTypes.number,
    }) */
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
};


export default VendorForm;
