import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import firebase from 'firebase.js';
import moment from 'moment';
import { checkUserData, AUTH_UPDATE_COUPON_DATA } from './auth';

import {
  fetchCollection,
  fetchDocument,
  createDocument,
  deleteDocument,
  updateDocument,
} from '../api';

export const COUPONS_FETCH_DATA_INIT = createAction('COUPONS_FETCH_DATA_INIT');
export const COUPONS_FETCH_DATA_SUCCESS = createAction(
  'COUPONS_FETCH_DATA_SUCCESS'
);
export const COUPONS_FETCH_DATA_FAIL = createAction('COUPONS_FETCH_DATA_FAIL');

export const COUPONS_DELETE_COUPON_INIT = createAction('COUPONS_DELETE_COUPON_INIT');
export const COUPONS_DELETE_COUPON_SUCCESS = createAction(
  'COUPONS_DELETE_COUPON_SUCCESS'
);
export const COUPONS_DELETE_COUPON_FAIL = createAction('COUPONS_DELETE_COUPON_FAIL');

export const COUPONS_CREATE_COUPON_INIT = createAction('COUPONS_CREATE_COUPON_INIT');
export const COUPONS_CREATE_COUPON_SUCCESS = createAction(
  'COUPONS_CREATE_COUPON_SUCCESS'
);
export const COUPONS_CREATE_COUPON_FAIL = createAction('COUPONS_CREATE_COUPON_FAIL');

export const COUPONS_MODIFY_COUPON_INIT = createAction('COUPONS_MODIFY_COUPON_INIT');
export const COUPONS_MODIFY_COUPON_SUCCESS = createAction(
  'COUPONS_MODIFY_COUPON_SUCCESS'
);
export const COUPONS_MODIFY_COUPON_FAIL = createAction('COUPONS_MODIFY_COUPON_FAIL');

export const COUPONS_CLEAN_UP = createAction('COUPONS_CLEAN_UP');

const uploadCouponBanner = (uid, file) => {
  const storageRef = firebase.storage().ref();

  const fileExtension = file.name.split('.').pop();

  const fileName = `${uid}.${fileExtension}`;

  return storageRef.child(`couponBanners/${fileName}`).put(file);
};
const getCouponBannerUrl = (uid, file) => {
  const fileExtension = file.name.split('.').pop();

  const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;

  return `${bucketUrl}/o/couponBanners%2F${uid}.${fileExtension}?alt=media`;
};
const deleteBanner = (oldLogo) => {
  if (!oldLogo.includes('firebasestorage')) {
    return null;
  }
  const logoPath = oldLogo.split('couponBanners%2F').pop().split('?alt=media').shift();
  return firebase.storage().ref(`couponBanners/${logoPath}`).delete();
};

export const fetchCoupons = (couponId = '', vendorId = '') => {
  return async (dispatch, getState) => {
    dispatch(checkUserData());

    dispatch(COUPONS_FETCH_DATA_INIT());
    if (couponId !== '') {
      // toastr.success('', couponId);

      let coupon;
      try {
        coupon = await fetchDocument('coupons', couponId);
        coupon.from = moment(coupon.from.toDate()).format('ddd MMM DD YYYY');
        coupon.to = moment(coupon.to.toDate()).format('ddd MMM DD YYYY');
        coupon.createdAt = moment(coupon.createdAt.toDate()).format('ddd MMM DD YYYY');
      } catch (error) {
        toastr.error(String(error), error);
        return dispatch(COUPONS_FETCH_DATA_FAIL({ error }));
      }

      if (!coupon) {
        const errorMessage = 'Coupons not available';
        toastr.error(String(errorMessage), errorMessage);
        return dispatch(COUPONS_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const coupons = getState().coupons.data;
      coupons.push(coupon);

      return dispatch(
        COUPONS_FETCH_DATA_SUCCESS({
          data: coupons,
        })
      );
    }

    const { id } = getState().auth.userData;
    let coupons;

    try {
      const vendorRef = firebase.firestore().collection('vendors').doc(vendorId);
      const options = { queries: [{ attribute: "vendorRef", operator: "==", value: vendorRef }] };
      coupons = await fetchCollection('coupons', options);
      let copy;
      coupons.map(couponItem => {
        copy = couponItem;
        copy.from = moment(copy.from.toDate()).format('ddd MMM DD YYYY');
        copy.to = moment(copy.to.toDate()).format('ddd MMM DD YYYY');
        copy.createdAt = moment(copy.createdAt.toDate()).format('ddd MMM DD YYYY');
        return copy;
      });

    } catch (error) {
      toastr.error(String(error), error);
      return dispatch(COUPONS_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      COUPONS_FETCH_DATA_SUCCESS({
        data: coupons.filter((coupon) => coupon.id !== id),
      })
    );
  };
};


export const deleteCoupon = (id) => {
  return async (dispatch, getState) => {
    dispatch(COUPONS_DELETE_COUPON_INIT());
    const { locale } = getState().preferences;
    const { couponBanner } = getState()
      .coupons.data.filter((coupon) => coupon.id === id)
      .pop();

    const deleteBannerTask = couponBanner ? deleteBanner(couponBanner) : null;
    const deleteCouponTask = deleteDocument('coupons', id);

    try {
      await Promise.all([/** deleteThumbnailTask, deletePdfTask , */deleteBannerTask, deleteCouponTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        COUPONS_DELETE_COUPON_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The coupon was deleted.');
    return dispatch(COUPONS_DELETE_COUPON_SUCCESS({ id }));
  };
};

export const createCoupon = ({
  title,
  description,
  terms,
  badge,
  badgeSub,
  from,
  code,
  qrcode,
  link,
  linkLabel,
  textcode,
  to,
  days,
  validity,
  displayedAs,
  isDiscountCode,
  codeDescription,
  // createdAt,
  file,
  vendorId,
}) => {
  return async (dispatch, getState) => {
    dispatch(COUPONS_CREATE_COUPON_INIT());
    const { locale } = getState().preferences;

    const ref = firebase.firestore().collection('coupons').doc();
    const uid = ref.id;

    const vendorRef = firebase.firestore().collection('vendors').doc(vendorId);
    let couponBannerUrl = null;
    let uploadBannerTask = null;
    if (file) {
      couponBannerUrl = getCouponBannerUrl(uid, file);
      uploadBannerTask = uploadCouponBanner(uid, file);
    }

    let couponData = {
      title,
      description,
      terms,
      // code,
      from: moment(from.slice(4)).toDate(),
      to: moment(to.slice(4)).toDate(),
      displayedAs: JSON.parse(displayedAs),
      isDiscountCode,
      codeDescription,
      vendorRef,
      createdAt: moment(Date()).toDate()
    };
    if(code) {
      couponData = {
        ...couponData,
        code
      };
    }
    if(qrcode) {
      couponData = {
        ...couponData,
        qrcode
      };
    }
    if(link) {
      couponData = {
        ...couponData,
        link
      };
    }
    if(linkLabel) {
      couponData = {
        ...couponData,
        linkLabel
      };
    }
    if(textcode) {
      couponData = {
        ...couponData,
        textcode
      };
    }
    if(couponBannerUrl) {
      couponData = {
        ...couponData,
        couponBanner: couponBannerUrl
      };
    }
    if(validity) {
      couponData = {
        ...couponData,
        validity
      };
    }
    if(!isDiscountCode) {
      couponData = {
        ...couponData,
        badge,
        badgeSub,
        days: JSON.parse(days),
        // validity,
      };
    }

    const createCouponsDbTask = createDocument('coupons', uid, couponData);

    try {
      await Promise.all([
        uploadBannerTask,
        createCouponsDbTask,
      ]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        COUPONS_CREATE_COUPON_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Coupon created successfully');
    return dispatch(COUPONS_CREATE_COUPON_SUCCESS({ coupon: couponData }));
  };
};


export const modifyCoupon = ({
  title,
  description,
  terms,
  badge,
  badgeSub,
  code,
  qrcode,
  link,
  linkLabel,
  textcode,
  from,
  to,
  days,
  validity,
  displayedAs,
  isDiscountCode,
  codeDescription,
  file,
  // createdAt,
  id,
}) => {

  return async (dispatch) => {
    let bannerToSend = null;
    // let deleteBannerTask = null;
    let uploadBannerTask = null;
    if (file) {
      bannerToSend = getCouponBannerUrl(id, file);
      // deleteBannerTask = deleteBanner(logoUrl);
      uploadBannerTask = uploadCouponBanner(id, file);
    }

    let couponData = {
      title,
      description,
      terms,
      // code,
      displayedAs: JSON.parse(displayedAs),
      isDiscountCode,
      codeDescription,
      from: moment(from.slice(4)).toDate(),
      createdAt: moment(Date()).toDate(),
      to: moment(to.slice(4)).toDate()
    };
    if(code) {
      couponData = {
        ...couponData,
        code
      };
    }
    if(qrcode) {
      couponData = {
        ...couponData,
        qrcode
      };
    }
    if(link) {
      couponData = {
        ...couponData,
        link
      };
    }
    if(linkLabel) {
      couponData = {
        ...couponData,
        linkLabel
      };
    }
    if(textcode) {
      couponData = {
        ...couponData,
        textcode
      };
    }
    if(bannerToSend) {
      couponData = {
        ...couponData,
        couponBanner: bannerToSend,
      };
    } else {
      couponData = {
        ...couponData,
        couponBanner: null,
      };
    }
    if(validity) {
      couponData = {
        ...couponData,
        validity
      };
    }
    if(!isDiscountCode) {
      couponData = {
        ...couponData,
        badge,
        badgeSub,
        days: JSON.parse(days),
        // validity,
      };
    }

    const updateCouponDbTask = updateDocument('coupons', id, couponData);

    try {
      await Promise.all([
        // deleteBannerTask,
        uploadBannerTask,
        updateCouponDbTask
      ]);

    } catch (error) {
      toastr.error('', String(error));
      return dispatch(
        COUPONS_MODIFY_COUPON_FAIL({
          error: String(error),
        })
      );
    }

    const { uid } = firebase.auth().currentUser;

    if (id === uid) {
      dispatch(AUTH_UPDATE_COUPON_DATA({ ...couponData, id }));
    }


    return dispatch(COUPONS_MODIFY_COUPON_SUCCESS({ coupon: couponData, id }));
  };
};
export const deleteCouponBanner = ({banner, id, title, description, from, to}) => {
  deleteBanner(banner);
  return async (dispatch) => {
    const couponData = {
      title,
      description,
      from: moment(from.slice(4)).toDate(),
      to: moment(to.slice(4)).toDate(),
      couponBanner: null,
    };
    const updateCouponDbTask = updateDocument('coupons', id, couponData);
    try {
      await Promise.all([
        updateCouponDbTask
      ]);

    } catch (error) {
      toastr.error('', String(error));
      return dispatch(
        COUPONS_MODIFY_COUPON_FAIL({
          error: String(error),
        })
      );
    }

    const { uid } = firebase.auth().currentUser;

    if (id === uid) {
      dispatch(AUTH_UPDATE_COUPON_DATA({ ...couponData, id }));
    }

    return dispatch(COUPONS_MODIFY_COUPON_SUCCESS({ coupon: couponData, id }));
  };
};
export const couponsCleanUp = () => (dispatch) => dispatch(COUPONS_CLEAN_UP());
