import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducer as toastrReducer } from 'react-redux-toastr';

import { authReducer } from './auth';
import { usersReducer } from './users';
import { vendorsReducer } from './vendors';
import { cataloguesReducer } from './catalogues';
import { couponsReducer } from './coupons';
import { bannersReducer } from './banners';
import { categoriesReducer } from './categories';
import { vendorCategoriesReducer } from './vendorCategories';


import { preferencesReducer } from './preferences';

export default combineReducers({
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      blacklist: ['error', 'loading'],
    },
    authReducer
  ),
  preferences: persistReducer(
    { key: 'preferences', storage },
    preferencesReducer
  ),
  users: persistReducer(
    {
      key: 'users',
      storage,
      blacklist: ['error', 'loading'],
    },
    usersReducer
  ),
  vendors: persistReducer(
    {
      key: 'vendors',
      storage,
      blacklist: ['error', 'loading'],
    },
    vendorsReducer
  ),
  catalogues: persistReducer(
    {
      key: 'catalogues',
      storage,
      blacklist: ['error', 'loading'],
    },
    cataloguesReducer
  ),
  coupons: persistReducer(
    {
      key: 'coupons',
      storage,
      blacklist: ['error', 'loading'],
    },
    couponsReducer
  ),
  banners: persistReducer(
    {
      key: 'banners',
      storage,
      blacklist: ['error', 'loading'],
    },
    bannersReducer
  ),
  categories: persistReducer(
    {
      key: 'categories',
      storage,
      blacklist: ['error', 'loading'],
    },
    categoriesReducer
  ),
  vendorCategories: persistReducer(
    {
      key: 'vendorCategories',
      storage,
      blacklist: ['error', 'loading'],
    },
    vendorCategoriesReducer
  ),
  toastr: toastrReducer,
  
});
