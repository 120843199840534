import React, { useEffect, useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';

import CategoryForm from 'components/CategoryForm';
import { createCategory, modifyCategory, fetchCategories } from 'state/actions/categories';
import paths from 'pages/Router/paths';

const schema = yup.object().shape({
  name: yup.string().required(),

  // url: yup.string().required(),
  // createdAt: yup.string().required(),
});

const Category = () => {
  const { id } = useParams();

  const isEditing = useMemo(() => !!id, [id]);
   
  const { success, categoryData, error } = useSelector(
    (state) => ({
      success: state.categories.success,
      categoryData: state.categories.data.find((user) => user.id === id),
      error: state.categories.error,
    }),
    shallowEqual
  );    
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing) {
      if (!categoryData) {
        dispatch(fetchCategories(id));
      }        
    }


  }, [isEditing, id, categoryData, dispatch]);

  const redirect = ((isEditing && error) || success) && (
    <Redirect to={`${paths.CATEGORIES}`} />
  );

  const editCategoryMessage = "Edit Category";

  const newCategoryMessage = "New Category";

  const onSubmitHandler = (value) => {


    if (isEditing) {

      const newCategory = {
        ...value,
        id,
      };

      dispatch(modifyCategory(newCategory));
    } else {
      const newCategory = {
        ...value,
      };
      dispatch(createCategory(newCategory));
    }
  };

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editCategoryMessage : newCategoryMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !categoryData ? (
          <ClipLoader />
        ) : (
          <CategoryForm
            isEditing={isEditing}
            category={
              isEditing
                ? categoryData
                : {
                    name: '',
                    subcategories: '',
                  }
            }
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
    </>
  );
};

export default Category;
