/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { categoriesCleanUp } from 'state/actions/categories';
import { useFormatMessage } from 'hooks';


import './VendorForm.scss';

const CategoryForm = ({  category, onSubmitHandler, schema }) => {
    
  const { loading, success } = useSelector(
    (state) => ({
      loading: state.categories.loading,
      success: state.categories.success,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: { ...category },
    resolver: yupResolver(schema),
  });


  const [form,setForm] = useState([]);
  useEffect(() => { 
    if(category.subcategories!==""){

      /* eslint no-param-reassign: "error" */
      category.subcategories.map(object => { 
        delete object.slug; 
        object.errors = {name:null, group_id:null};
        return object; 
    }); 
      setForm(category.subcategories);
    }
  
    return () => dispatch(categoriesCleanUp());
  }, [dispatch, success, setValue]);

  const prevIsValid = () => {
    if (form.length === 0) {
      return true;
    }

    const someEmpty = form.some(
      (item) => item.name === "" || item.group_id === ""
    );

    if (someEmpty) {
      form.map((item, index) => {
        const allPrev = [...form];

        if (form[index].name === "") {
          allPrev[index].errors.name = "Name is required";
        }

        if (form[index].group_id === "") {
          allPrev[index].errors.group_id = "Group Id is required";
        }
        setForm(allPrev);
        return '';
      });
    }

    return !someEmpty;
  };

  const handleAddLink = (e) => {
    e.preventDefault();
    const inputState = {
      name: "",
      group_id:1,

      errors: {
        name: null,
        group_id: null,
      },

    };

    if (prevIsValid()) {
      setForm((prev) => [...prev, inputState]);
    }
  };

  const onChange = (index, event) => {
    event.preventDefault();
    event.persist();

    setForm((prev) => {
      return prev.map((item, i) => {
        if (i !== index) {
          return item;
        }

        return {
          ...item,
          [event.target.name]: event.target.value,

          errors: {
            ...item.errors,
            [event.target.name]:
              event.target.value.length > 0
                ? null 
                : [event.target.name], // + " Is required",
          },
        };
      });
    });
  };

  const handleRemoveField = (e, index) => {
    e.preventDefault();

    setForm((prev) => prev.filter((item) => item !== prev[index]));
  };


  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                Category Information
              </p>
            </header>
            <div className="card-content">


              <form onSubmit={handleSubmit(onSubmitHandler)}>
              <input
                          className="input"
                          type="hidden"
                          ref={register}
                          name="subcategories"
                          value= { JSON.stringify(form)}  

                        />
              <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Name
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          ref={register}
                          name="name"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Subcategories
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
  
        {form.map((item, index) => (
          <div className="field is-horizontal" key={`item-${index+1}`}>
            <div className="field-body">
              <input
                type="text"
                className={
                  item.errors.name
                    ? "input is-danger"
                    : "input"
                }
                name="name"
                placeholder="Name"
                value={item.name}
                required
                onChange={(e) => onChange(index, e)}
              />

              {item.errors.name && (
                <div className="help is-danger">{item.errors.name}</div>
              )}
            </div>

            <div className="field-body">
              <input
                type="number"
                className={
                  item.errors.group_id
                  ? "input is-danger"
                  : "input"
                }
                name="group_id"
                placeholder="Group"
                value={item.group_id}
                onChange={(e) => onChange(index, e)}
              />

              {item.errors.group_id && (
                <div className="invalid-feedback">{item.errors.group_id}</div>
              )}
            </div>
            
            <button
              className="button is-danger"
              onClick={(e) => handleRemoveField(e, index)}
            >
              X
            </button>
          </div>
        ))}
       <button className="button is-primary" onClick={handleAddLink}>
        <span className="icon is-small">
                  <i className="mdi mdi-plus-box" />
                </span>
        </button>
                      </div>
                    </div>
                  </div>
                </div>
              
                <hr />

        
                                      
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>{useFormatMessage('UserForm.submit')}</span>
                          </button>
                        </div>   
                      </div>
                    </div>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>

      </div>
    </>
  );
};

CategoryForm.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
};


export default CategoryForm;
