import React, { useEffect, useMemo } from 'react';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';

import CatalogueForm from 'components/CatalogueForm';
import { createCatalogue, modifyCatalogue, fetchCatalogues } from 'state/actions/catalogues';
import paths from 'pages/Router/paths';

const schema = yup.object().shape({
  title: yup.string().required(),
  from: yup.string().required(),
  to: yup.string().required(),
});

const Catalogue = () => {
  const { id } = useParams();
  const { vendorId } = useParams();

  const isEditing = useMemo(() => !!id, [id]);
  const history = useHistory();
  const { success, catalogueData, error } = useSelector(
    (state1) => ({
      success: state1.catalogues.success,
      catalogueData: state1.catalogues.data.find((user) => user.id === id),
      error: state1.catalogues.error,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (isEditing) {
      if (!catalogueData) {
        dispatch(fetchCatalogues(id));
      }
    }
  }, [isEditing, id, catalogueData, dispatch]);
  if (catalogueData !== undefined)
    catalogueData.allCategories = history.location.state.allCategories;
  const redirect = ((isEditing && error) || success) && (
    <Redirect to={`${paths.VENDORS}/${history.location.state.vendorId}`} />
  );

  const editCatalogueMessage = "Edit Catalogue";

  const newCatalogueMessage = "New Catalogue";

  const onSubmitHandler = (value) => {
    if (isEditing) {
      const newCatalogue = {
        ...value,
        filePdf: value?.filePdf[0] || null,
        id,
      };

      dispatch(modifyCatalogue(newCatalogue));
    } else {
      const newCatalogue = {
        ...value,
        filePdf: value?.filePdf[0] || null,
        vendorId,
      };
      dispatch(createCatalogue(newCatalogue));
    }
  };
  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editCatalogueMessage : newCatalogueMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !catalogueData ? (
          <ClipLoader />
        ) : (
          <CatalogueForm
            isEditing={isEditing}
            catalogue={
              isEditing
                ? catalogueData
                : {
                  title: '',
                  from: new Date().toDateString(),
                  to: new Date().toDateString(),
                  allCategories: history.location.state.allCategories,
                  vendorId
                }
            }
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
    </>
  );
};
export default Catalogue;
