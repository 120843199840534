import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';

import { firebaseError } from 'utils';
import firebase from 'firebase.js';
import { checkUserData, AUTH_UPDATE_BANNER_DATA } from './auth';

import {
  fetchCollection,
  fetchDocument,
  createDocument,
  deleteDocument,
  updateDocument,
} from '../api';

export const BANNERS_FETCH_DATA_INIT = createAction('BANNERS_FETCH_DATA_INIT');
export const BANNERS_FETCH_DATA_SUCCESS = createAction(
  'BANNERS_FETCH_DATA_SUCCESS'
);
export const BANNERS_FETCH_DATA_FAIL = createAction('BANNERS_FETCH_DATA_FAIL');

export const BANNERS_DELETE_BANNER_INIT = createAction('BANNERS_DELETE_BANNER_INIT');
export const BANNERS_DELETE_BANNER_SUCCESS = createAction(
  'BANNERS_DELETE_BANNER_SUCCESS'
);
export const BANNERS_DELETE_BANNER_FAIL = createAction('BANNERS_DELETE_BANNER_FAIL');

export const BANNERS_CREATE_BANNER_INIT = createAction('BANNERS_CREATE_BANNER_INIT');
export const BANNERS_CREATE_BANNER_SUCCESS = createAction(
  'BANNERS_CREATE_BANNER_SUCCESS'
);
export const BANNERS_CREATE_BANNER_FAIL = createAction('BANNERS_CREATE_BANNER_FAIL');

export const BANNERS_MODIFY_BANNER_INIT = createAction('BANNERS_MODIFY_BANNER_INIT');
export const BANNERS_MODIFY_BANNER_SUCCESS = createAction(
  'BANNERS_MODIFY_BANNER_SUCCESS'
);
export const BANNERS_MODIFY_BANNER_FAIL = createAction('BANNERS_MODIFY_BANNER_FAIL');

export const BANNERS_CLEAN_UP = createAction('BANNERS_CLEAN_UP');


export const fetchBanners = (bannerId = '') => {
  return async (dispatch, getState) => {
    dispatch(checkUserData());

    dispatch(BANNERS_FETCH_DATA_INIT());
    if (bannerId) {
      let banner;
      try {
        banner = await fetchDocument('banners', bannerId);
        banner.createdAt = moment( banner.createdAt.toDate()).format('ddd MMM DD YYYY');
      } catch (error) {
        toastr.error(String(error), error);
        return dispatch(BANNERS_FETCH_DATA_FAIL({ error }));
      }

      if (!banner) {
        const errorMessage = 'Banners not available';
        toastr.error(String(errorMessage), errorMessage);
        return dispatch(BANNERS_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const banners = getState().banners.data;
      banners.push(banner);

      return dispatch(
        BANNERS_FETCH_DATA_SUCCESS({
          data: banners,
        })
      );
    }

    const { id } = getState().auth.userData;
    let banners;

    try {
      banners = await fetchCollection('banners');
      let copy;
      banners.map(bannerItem => {
        copy =  bannerItem;
        copy.createdAt = moment( copy.createdAt.toDate()).format('ddd MMM DD YYYY');
        return copy;
      });
    } catch (error) {
      toastr.error(String(error), error);
      return dispatch(BANNERS_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      BANNERS_FETCH_DATA_SUCCESS({
        data: banners.filter((banner) => banner.id !== id),
      })
    );
  };
};

const deleteImage = (oldImage) => {
  if (!oldImage.includes('firebasestorage')) {
    return null;
  }
  const imagePath = oldImage.split('banners%2F').pop().split('?alt=media').shift();
  return firebase.storage().ref(`banners/${imagePath}`).delete();
};

export const deleteBanner = (id) => {
  return async (dispatch, getState) => {
    dispatch(BANNERS_DELETE_BANNER_INIT());
    const { locale } = getState().preferences;
    const { imageUrl } = getState()
      .banners.data.filter((banner) => banner.id === id)
      .pop();

    const deleteImageTask = imageUrl ? deleteImage(imageUrl) : null;

    const deleteBannerTask = deleteDocument('banners', id);

    try {
      await Promise.all([deleteImageTask, deleteBannerTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        BANNERS_DELETE_BANNER_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The banner was deleted.');
    return dispatch(BANNERS_DELETE_BANNER_SUCCESS({ id }));
  };
};

const uploadImage = (uid, file) => {
  const storageRef = firebase.storage().ref();

  const fileExtension = file.name.split('.').pop();

  const fileName = `${uid}.${fileExtension}`;

  return storageRef.child(`banners/${fileName}`).put(file);
};

const getImageUrl = (uid, file) => {
  const fileExtension = file.name.split('.').pop();

  const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;

  return `${bucketUrl}/o/banners%2F${uid}.${fileExtension}?alt=media`;
};

export const createBanner = ({
  placeholder,
  url,
  file,
}) => {
  return async (dispatch, getState) => {
    dispatch(BANNERS_CREATE_BANNER_INIT());
    const { locale } = getState().preferences;

    const ref =  firebase.firestore().collection('banners').doc();
    const uid = ref.id;

    let uploadImageTask = null;
    let imageUrl = null;
    if (file) {
      imageUrl = getImageUrl(uid, file);
      uploadImageTask = uploadImage(uid, file);
    }
    const bannerData = { placeholder, url, imageUrl,  createdAt: moment(Date()).toDate() };

    const createBannersDbTask = createDocument('banners', uid, bannerData);
    
    try {
      await Promise.all([
        uploadImageTask,
        createBannersDbTask,
      ]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        BANNERS_CREATE_BANNER_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Banner created successfully');
    return dispatch(BANNERS_CREATE_BANNER_SUCCESS({ banner: bannerData}));
  };
};


export const modifyBanner= ({
    placeholder,
    url,
    file,
    id,
}) => {
  return async (dispatch, getState) => {
    dispatch(BANNERS_MODIFY_BANNER_INIT());
    const banner = getState().banners.data.find((thisBanner) => thisBanner.id === id);
    const { imageUrl } = banner;
    let deleteImageTask;
    let uploadImageTask;
    let newImageUrl = null;
    let imageToSend = null;
    if (file) {
      newImageUrl = getImageUrl(id, file);
      deleteImageTask = imageUrl && deleteImage(imageUrl);
      uploadImageTask = uploadImage(id, file);
      imageToSend = newImageUrl;
    }else{
      imageToSend = imageUrl;
    }

    const bannerData = {
        placeholder,
        url,
        createdAt: moment(Date()).toDate(),
      imageUrl: imageToSend,
    };
    const updateBannerDbTask = updateDocument('banners', id, bannerData);

    try {
      await Promise.all([deleteImageTask, uploadImageTask, updateBannerDbTask]);
    } catch (error) {
      toastr.error('', String(error));
      return dispatch(
        BANNERS_MODIFY_BANNER_FAIL({
          error: String(error),
        })
      );
    }

    const { uid } = firebase.auth().currentUser;
    if (id === uid) {
      dispatch(AUTH_UPDATE_BANNER_DATA({ ...bannerData, id }));
    }

    return dispatch(BANNERS_MODIFY_BANNER_SUCCESS({ banner: bannerData, id }));
  };
};
export const bannersCleanUp = () => (dispatch) => dispatch(BANNERS_CLEAN_UP());
