import React, { useEffect, useMemo,useState } from 'react';
import { useParams, Redirect,Link } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';

import VendorForm from 'components/VendorForm';

import { createVendor, modifyVendor, fetchVendors } from 'state/actions/vendors';
import paths from 'pages/Router/paths';
import { deleteCatalogue, fetchCatalogues , cataloguesCleanUp} from 'state/actions/catalogues';

import { deleteCoupon, fetchCoupons ,  couponsCleanUp} from 'state/actions/coupons';
import classNames from 'classnames';
import { useFormatMessage, useFormatDate } from 'hooks';
import Table from 'components/Table';
import ConfirmationModal from 'components/ConfirmationModal';
import classes from './Users.module.scss';

const schema = yup.object().shape({
  name: yup.string().required(),
  logo: yup.string().notRequired(),
  // logoLandscape: yup.string().notRequired(),
  card: yup.string().notRequired(),
  loyaltyCard: yup.bool().notRequired(),
  priority: yup.number().notRequired()
});

const Vendor = () => {
  const { id } = useParams();

  const isEditing = useMemo(() => !!id, [id]);

  const { success, vendorData, error } = useSelector(
    (state) => ({
      success: state.vendors.success,
      vendorData: state.vendors.data.find((user) => user.id === id),
      error: state.vendors.error,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing) {
      if (!vendorData) {
        dispatch(fetchVendors(id));
      }
    }
  }, [isEditing, id, vendorData, dispatch]);

  const redirect = ((isEditing && error) || success) && (
    <Redirect to={paths.ROOT} />
  );

   const editVendorMessage = "Edit Vendor";

  const newVendorMessage = "New Vendor";

  const onSubmitHandler = (value) => {
    const newVendor = {
      ...value,
      file: value?.file[0] || null,
      file1: value?.file1[0] || null,
      isEditing,
      id,
    };
 

    if (isEditing) {
      dispatch(modifyVendor(newVendor));
    } else {
      dispatch(createVendor(newVendor));
    }
  };
  const vendorId = id;

  // CATALOUGS CODE
  const [search, setSearch] = useState('');

  const { cataloguesList, isAdmin,error1, loading, deleted } = useSelector(
    (state1) => ({
      cataloguesList: state1.catalogues.data,
      isAdmin: state1.auth.userData.isAdmin,
      error1: state1.catalogues.error,
      loading: state1.catalogues.loading,
      deleted: state1.catalogues.deleted,
    }),
    shallowEqual
  );

  const {allCategories} = cataloguesList;
  useEffect(() => {
     if (isEditing) {
      dispatch(fetchCatalogues('',vendorId));
     }

    return () => dispatch(cataloguesCleanUp());
  }, [dispatch, isAdmin]);
  const data = search
  ? cataloguesList.filter((el) => {
      /* const clonedElem = { ...el };
      delete clonedElem.id;
      delete clonedElem.logoUrl;
      return Object.values(clonedElem).some((field) =>
        field.toLowerCase().includes(search.toLowerCase())
      ); */
      return el?.title?.toLowerCase()?.includes(search.toLowerCase());
    })
  : cataloguesList;


  const [deleteModal, setDeleteModal] = useState({
    userId: null,
    isOpen: false,
  });

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        userId: null,
        isOpen: !prevState.isOpen,
      }));
    }
  }, [deleted, loading]);


  const onRemoveButtonClickHandler = (userId) => {
    setDeleteModal((prevState) => ({
      userId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ userId: null, isOpen: false });
  };

  const onDeleteCatalogueHandler = () => {
    dispatch(deleteCatalogue(deleteModal.userId));
  };

  const deleteMessage = useFormatMessage('Users.delete');

  const confirmMessage = useFormatMessage('Users.confirm');

  const permDeleteMessage = useFormatMessage('Users.permDelete');

  const cancelMessage = useFormatMessage('Users.cancel');


  const columns = [
    {
      Header: '',
      accessor: 'thumbnailUrl',
      Cell: ({ row }) => (
        <div className="image">
          <img src={row.original.thumbnailUrl} alt="" />
        </div>
      ),
      disableSortBy: true,
    },
    {
      Header: "Title",
      accessor: 'title',
    },    
    /** {
      Header: "PDF",
      accessor: 'pdfUrl',
    }, */
    {
      Header: 'PDF',
      id: 'actions1',
      accessor: 'actions',
      Cell: ({ row }) => (
        <>
          {!row.original.isAdmin && (
            <div className="buttons">
              <a
                href={`${row.original.pdfUrl}`}
                // target="_blank"
                className="button is-small is-primary"
              >
                <span className="icon is-small">
                  <i className="mdi mdi-download" />
                </span>
              </a>
            </div>
          )}
        </>
      ),
      disableSortBy: true,
    },
    {
      Header: "From",
      accessor: 'from',
      sortType: (a, b) => {
        return new Date(b.values.from) - new Date(a.values.from);
      },
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.from, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </small>
      ),
    },
    {
      Header: "To",
      accessor: 'to',
      sortType: (a, b) => {
        return new Date(b.values.to) - new Date(a.values.to);
      },
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.to, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </small>
      ),
    },
    {
      Header: "Last Modified",
      accessor: 'created',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.from, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </small>
      ),
    },
    // Buttons
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <>
          {!row.original.isAdmin && (
            <div className="buttons is-right">
              <Link
                to={{ pathname:`/catalogue/${row.original.id}`, state: { vendorId , allCategories } }}
                className="button is-small is-primary"
              >
                <span className="icon is-small">
                  <i className="mdi mdi-account-edit" />
                </span>
              </Link>

              <button
                type="button"
                className="button is-small is-danger"
                onClick={() => onRemoveButtonClickHandler(row.original.id)}
              >
                <span className="icon is-small">
                  <i className="mdi mdi-trash-can" />
                </span>
              </button>
            </div>
          )}
        </>
      ),
      disableSortBy: true,
    },
  ];
  // COUPONS CODE
  const [searchCoupons, setSearchCoupons] = useState('');

  const { couponsList,errorCoupons, loadingCoupon, deletedCoupon } = useSelector(
    (state2) => ({
      couponsList: state2.coupons.data,
      errorCoupons: state2.coupons.error,
      loadingCoupon: state2.coupons.loading,
      deletedCoupon: state2.coupons.deleted,
    }),
    shallowEqual
  );

  useEffect(() => {
     if (isEditing) {
      dispatch(fetchCoupons('',vendorId));
     }

    return () => dispatch(couponsCleanUp());
  }, [dispatch, isAdmin]);
  const dataCoupons = search
  ? couponsList.filter((el) => {
      /* const clonedElem = { ...el };
      delete clonedElem.id;
      delete clonedElem.logoUrl;
      return Object.values(clonedElem).some((field) =>
        field.toLowerCase().includes(search.toLowerCase())
      ); */
      return el?.title?.toLowerCase()?.includes(search.toLowerCase());
    })
  : couponsList;


  const [deleteCouponModal, setDeleteCouponModal] = useState({
    userId: null,
    isOpen: false,
  });

  useEffect(() => {
    if (deletedCoupon && !loadingCoupon) {
      setDeleteCouponModal((prevState) => ({
        userId: null,
        isOpen: !prevState.isOpen,
      }));
    }
  }, [deletedCoupon, loadingCoupon]);


  const onRemoveButtonCouponClickHandler = (userId) => {
    setDeleteCouponModal((prevState) => ({
      userId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalCouponHandler = () => {
    setDeleteCouponModal({ userId: null, isOpen: false });
  };

  const onDeleteCouponHandler = () => {
    dispatch(deleteCoupon(deleteCouponModal.userId));
  };

  const columnsCoupons = [
    {
      Header: "Title",
      accessor: 'title',
    },
    {
      Header: "Description",
      accessor: 'description',
    },
    {
      Header: "Terms",
      accessor: 'terms',
    },
    {
      Header: "Badge Title",
      accessor: 'badge',
    },
    {
      Header: "Badge Subtitle",
      accessor: 'badgeSub',
    },
    {
      Header: "From",
      accessor: 'from',
      sortType: (a, b) => {
        return new Date(b.values.from) - new Date(a.values.from);
      },
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.from, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </small>
      ),
    },
    {
      Header: "To",
      accessor: 'to',
      sortType: (a, b) => {
        return new Date(b.values.to) - new Date(a.values.to);
      },
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.to, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </small>
      ),
    },
    {
      Header: "Last Modified",
      accessor: 'created',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </small>
      ),
    },

    // Buttons
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <>
          {!row.original.isAdmin && (
            <div className="buttons is-right">
              <Link
                to={{ pathname:`/coupon/${row.original.id}`, state: { vendorId  } }}
                className="button is-small is-primary"
              >
                <span className="icon is-small">
                  <i className="mdi mdi-account-edit" />
                </span>
              </Link>

              <button
                type="button"
                className="button is-small is-danger"
                onClick={() => onRemoveButtonCouponClickHandler(row.original.id)}
              >
                <span className="icon is-small">
                  <i className="mdi mdi-trash-can" />
                </span>
              </button>
            </div>
          )}
        </>
      ),
      disableSortBy: true,
    },
  ];

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editVendorMessage : newVendorMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !vendorData ? (
          <ClipLoader />
        ) : (
          <VendorForm
            isEditing={isEditing}
            vendor={
              isEditing
                ? vendorData
                : {
                    name: '',
                    card: '',
                  }
            }
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteCatalogueHandler}
          onCancel={onCloseModalHandler}
        />
      )}

    {deleteCouponModal.isOpen && (
        <ConfirmationModal
          isActive={deleteCouponModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteCouponHandler}
          onCancel={onCloseModalCouponHandler}
        />
      )}
      {isEditing ? <div> 
        <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">Catalogues</h1>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <Link className="button" to={{pathname:`/catalogue/new/${vendorId}`, state: { vendorId,  allCategories }}}>
                  New Catalogue
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className={classNames('card-header-title', classes.tableHeader)}>
              <span>{useFormatMessage('Users.search')}</span>
              <input
                type="text"
                className="input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </p>
          </header>
          <div className="b-table">
            {loading ? <ClipLoader /> : <Table columns={columns} data={data} />}
            {error1 && 'Show error'}
          </div>
        </div>
      </section>


      
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">Coupons</h1>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
              <Link className="button" to={{pathname:`/coupon/new/${vendorId}`, state: { vendorId  }}}>
                  New Coupon
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className={classNames('card-header-title', classes.tableHeader)}>
              <span>{useFormatMessage('Users.search')}</span>
              <input
                type="text"
                className="input"
                value={searchCoupons}
                onChange={(e) => setSearchCoupons(e.target.value)}
              />
            </p>
          </header>
          <div className="b-table">
            {loadingCoupon ? <ClipLoader /> : <Table columns={columnsCoupons} data={dataCoupons} />}
            {errorCoupons && 'Show error'}
          </div>
        </div>
      </section>
      </div> : ''}

    </>
  );
};

export default Vendor;
