import { createReducer } from 'redux-act';


import {
    VENDOR_CATEGORIES_FETCH_DATA_INIT,
    VENDOR_CATEGORIES_FETCH_DATA_SUCCESS,
    VENDOR_CATEGORIES_FETCH_DATA_FAIL,
    VENDOR_CATEGORIES_CREATE_CATEGORY_INIT,
    VENDOR_CATEGORIES_CREATE_CATEGORY_SUCCESS,
    VENDOR_CATEGORIES_CREATE_CATEGORY_FAIL,
    VENDOR_CATEGORIES_DELETE_CATEGORY_FAIL,
    VENDOR_CATEGORIES_DELETE_CATEGORY_SUCCESS,
    VENDOR_CATEGORIES_DELETE_CATEGORY_INIT,
    VENDOR_CATEGORIES_MODIFY_CATEGORY_FAIL,
    VENDOR_CATEGORIES_MODIFY_CATEGORY_INIT,
    VENDOR_CATEGORIES_MODIFY_CATEGORY_SUCCESS,
    VENDOR_CATEGORIES_CLEAN_UP
  } from 'state/actions/vendorCategories';
  
  const initialState = {
    data: [],
    loading: false,
    error: null,
    success: false,
    deleted: false,
  };

  
  export const vendorCategoriesReducer = createReducer(
    {
      [VENDOR_CATEGORIES_FETCH_DATA_INIT]: () => ({
        ...initialState,
        loading: true,
      }),
      [VENDOR_CATEGORIES_FETCH_DATA_SUCCESS]: (state, payload) => ({
        ...state,
        data: payload.data,
        loading: false,
        error: null,
      }),
      [VENDOR_CATEGORIES_FETCH_DATA_FAIL]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload.error,
      }),
      [VENDOR_CATEGORIES_CREATE_CATEGORY_INIT]: (state) => ({
        ...state,
        loading: true,
      }),
      [VENDOR_CATEGORIES_CREATE_CATEGORY_SUCCESS]: (state, payload) => ({
        ...state,
        data: state.data.concat(payload.category),
        loading: false,
        error: null,
        success: true,
      }),
      [VENDOR_CATEGORIES_CREATE_CATEGORY_FAIL]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload.error,
      }),
      [VENDOR_CATEGORIES_DELETE_CATEGORY_INIT]: (state) => ({
        ...state,
        loading: true,
      }),
      [VENDOR_CATEGORIES_DELETE_CATEGORY_SUCCESS]: (state, payload) => ({
        ...state,
        data: state.data.filter((elem) => elem.id !== payload.id),
        loading: false,
        error: null,
        deleted: true,
      }),
      [VENDOR_CATEGORIES_DELETE_CATEGORY_FAIL]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload.error,
      }),
      [VENDOR_CATEGORIES_MODIFY_CATEGORY_INIT]: (state) => ({
        ...state,
        loading: true,
      }),
      [VENDOR_CATEGORIES_MODIFY_CATEGORY_SUCCESS]: (state, payload) => ({
        ...state,
        data: !state.data
          ? []
          : state.data.map((elem) => {
              if (elem.id === payload.id) {
                return {
                  name: payload.category.placeholder,
                  subcategories: payload.category.subcategories,
                  createdAt: payload.category.createdAt,
                };
              }
              return elem;
            }),
        loading: false,
        error: null,
        success: true,
      }),
      [VENDOR_CATEGORIES_MODIFY_CATEGORY_FAIL]: (state, payload) => ({
        ...state,
        loading: false,
        error: payload.error,
      }),
      [VENDOR_CATEGORIES_CLEAN_UP]: (state) => ({
        ...state,
        loading: false,
        error: null,
        success: false,
        deleted: false,
      }),
    },
    initialState
  );
  