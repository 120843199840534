import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';

import { firebaseError } from 'utils';
import firebase from 'firebase.js';

import { checkUserData, AUTH_UPDATE_VENDOR_DATA } from './auth';

import {
  fetchCollection,
  fetchDocument,
  createDocument,
  deleteDocument,
  updateDocument,
} from '../api';

export const VENDORS_FETCH_DATA_INIT = createAction('VENDORS_FETCH_DATA_INIT');
export const VENDORS_FETCH_DATA_SUCCESS = createAction(
  'VENDORS_FETCH_DATA_SUCCESS'
);
export const VENDORS_FETCH_DATA_FAIL = createAction('VENDORS_FETCH_DATA_FAIL');

export const VENDORS_DELETE_VENDOR_INIT = createAction('VENDORS_DELETE_VENDOR_INIT');
export const VENDORS_DELETE_VENDOR_SUCCESS = createAction(
  'VENDORS_DELETE_VENDOR_SUCCESS'
);
export const VENDORS_DELETE_VENDOR_FAIL = createAction('VENDORS_DELETE_VENDOR_FAIL');

export const VENDORS_CREATE_VENDOR_INIT = createAction('VENDORS_CREATE_VENDOR_INIT');
export const VENDORS_CREATE_VENDOR_SUCCESS = createAction(
  'VENDORS_CREATE_VENDOR_SUCCESS'
);
export const VENDORS_CREATE_VENDOR_FAIL = createAction('VENDORS_CREATE_VENDOR_FAIL');

export const VENDORS_MODIFY_VENDOR_INIT = createAction('VENDORS_MODIFY_VENDOR_INIT');
export const VENDORS_MODIFY_VENDOR_SUCCESS = createAction(
  'VENDORS_MODIFY_VENDOR_SUCCESS'
);
export const VENDORS_MODIFY_VENDOR_FAIL = createAction('VENDORS_MODIFY_VENDOR_FAIL');

export const VENDORS_CLEAN_UP = createAction('VENDORS_CLEAN_UP');

const hasActiveCoupon = async(vendorId) =>  {
  let coupons;
  try {
    const vendorRef = firebase.firestore().collection('vendors').doc(vendorId);
    const options = { queries: [{ attribute: "vendorRef", operator: "==", value: vendorRef }] };
    coupons = await fetchCollection('coupons', options);
    let copy;
    if(coupons.length>0){
      coupons.map(couponItem => {
        copy = couponItem;
        copy.from = moment(copy.from.toDate()).format('ddd MMM DD YYYY');
        copy.to = moment(copy.to.toDate()).format('ddd MMM DD YYYY');
        copy.createdAt = moment(copy.createdAt.toDate()).format('ddd MMM DD YYYY');
        copy.isActive = moment().isSameOrBefore(copy.to);
        return copy;
      });
      const isActive = (element) => element.isActive === true;
      return coupons.some(isActive) ? 1 : -1;
    }
    return -1;
    
  } catch (error) {
    toastr.error(String(error), error);
    return -1;
  }
  
};


const hasActiveCatalogue = async (vendorId) => {
  let catalogues;
  try {
    const vendorRef = firebase.firestore().collection('vendors').doc(vendorId);
    const options = { queries: [{ attribute: "vendorRef", operator: "==", value: vendorRef }] };
    catalogues = await fetchCollection('catalogues', options);
    if(catalogues.length>0){
      let copy;
      catalogues.map(catalogueItem => {
        copy = catalogueItem;
        copy.from = moment(copy.from.toDate()).format('ddd MMM DD YYYY');
        copy.to = moment(copy.to.toDate()).format('ddd MMM DD YYYY');
        copy.createdAt = moment(copy.createdAt.toDate()).format('ddd MMM DD YYYY');
        copy.isActive = moment().isSameOrBefore(copy.to);
        return copy;
      });
      const isActive = (element) => element.isActive === true;
      return catalogues.some(isActive)? 1 : -1;
    }
    return -1;
  } catch (error) {
    toastr.error(String(error), error);
    return -1;
  }

};


export const fetchVendors = (vendorId = '') => {
  return async (dispatch, getState) => {
    dispatch(checkUserData());

    dispatch(VENDORS_FETCH_DATA_INIT());
    if (vendorId) {
      let vendor;
      try {
        vendor = await fetchDocument('vendors', vendorId);
        vendor.createdAt = moment( vendor.createdAt.toDate()).format('ddd MMM DD YYYY');
      } catch (error) {
        toastr.error(String(error), error);
        return dispatch(VENDORS_FETCH_DATA_FAIL({ error }));
      }

      if (!vendor) {
        const errorMessage = 'Vendors not available';
        toastr.error(String(errorMessage), "errorMessage");
        return dispatch(VENDORS_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const vendors = getState().vendors.data;
      vendors.push(vendor);

      return dispatch(
        VENDORS_FETCH_DATA_SUCCESS({
          data: vendors,
        })
      );
    }

    const { id } = getState().auth.userData;
    let vendors;

    try {
      vendors = await fetchCollection('vendors');
      vendors = await Promise.all(vendors.map(async (i) => {
        const copy=i;
        copy.activeCoupon=await hasActiveCoupon(copy.id);
        copy.activeCatalogue = await hasActiveCatalogue(copy.id);
        copy.createdAt = moment(copy.createdAt.toDate()).format('ddd MMM DD YYYY');
        return copy;
      }));
    } catch (error) {
      toastr.error(String(error), error);
      return dispatch(VENDORS_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      VENDORS_FETCH_DATA_SUCCESS({
        data: vendors.filter((vendor) => vendor.id !== id),
      })
    );
  };
};

const deleteLogo = (oldLogo) => {
  if (!oldLogo.includes('firebasestorage')) {
    return null;
  }
  const logoPath = oldLogo.split('vendors%2F').pop().split('?alt=media').shift();
  return firebase.storage().ref(`vendors/${logoPath}`).delete();
};
const deleteLogoLandscape = (oldLogo) => {
  if (!oldLogo.includes('firebasestorage')) {
    return null;
  }
  const logoPath = oldLogo.split('vendors%2F').pop().split('?alt=media').shift();
  return firebase.storage().ref(`vendors/${logoPath}`).delete();
};

export const deleteVendor = (id) => {
  return async (dispatch, getState) => {
    dispatch(VENDORS_DELETE_VENDOR_INIT());
    const { locale } = getState().preferences;
    const { logoUrl } = getState()
      .vendors.data.filter((vendor) => vendor.id === id)
      .pop();

    const { logoUrlLandscape } = getState()
      .vendors.data.filter((vendor) => vendor.id === id)
      .pop();

    const deleteLogoTask = logoUrl ? deleteLogo(logoUrl) : null;
    
    const deleteLogoLandscapeTask = logoUrlLandscape ? deleteLogoLandscape(logoUrlLandscape) : null;

    const deleteVendorTask = deleteDocument('vendors', id);
    const vendorRef = firebase.firestore().collection('vendors').doc(id);
    const options = {queries: [{attribute:"vendorRef", operator:"==" ,value:vendorRef}]};
    const coupons = await fetchCollection('coupons',options);
    const deleteCouponsTask = [];
    coupons.forEach(element => {
      deleteCouponsTask.push(deleteDocument('coupons', element.id));
    });

    const catalogues = await fetchCollection('catalogues',options);
    const deleteCataloguesTask = [];
    catalogues.forEach(element => {
      deleteCataloguesTask.push(deleteDocument('catalogues', element.id));
    });

    try {
      await Promise.all([deleteLogoTask, deleteVendorTask,deleteLogoLandscapeTask].concat(deleteCouponsTask).concat(deleteCataloguesTask));
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        VENDORS_DELETE_VENDOR_FAIL({
          error: errorMessage,
        })
      );
    }


    toastr.success('', 'The vendor was deleted.');
    return dispatch(VENDORS_DELETE_VENDOR_SUCCESS({ id }));
  };
};

const uploadLogo = (uid, file) => {
  const storageRef = firebase.storage().ref();

  const fileExtension = file.name.split('.').pop();

  const fileName = `${uid}.${fileExtension}`;

  return storageRef.child(`vendors/${fileName}`).put(file);
};

const uploadLandscapeLogo = (uid, file1) => {
  const storageRef = firebase.storage().ref();

  const fileExtension = file1.name.split('.').pop();

  const fileName = `${uid}_landscape.${fileExtension}`;

  return storageRef.child(`vendors/${fileName}`).put(file1);
};

const getLogoUrl = (uid, file) => {
  const fileExtension = file.name.split('.').pop();

  const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;

  return `${bucketUrl}/o/vendors%2F${uid}.${fileExtension}?alt=media`;
};
const getLogoUrlLandscape = (uid, file1) => {
  const fileExtension = file1.name.split('.').pop();

  const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;

  return `${bucketUrl}/o/vendors%2F${uid}_landscape.${fileExtension}?alt=media`;
};
export const createVendor = ({
  name,
  card,
  loyaltyCard,
  priority,
  file,
  file1,
  vendorCategory
}) => {
  return async (dispatch, getState) => {
    dispatch(VENDORS_CREATE_VENDOR_INIT());
    const { locale } = getState().preferences;

    const ref =  firebase.firestore().collection('vendors').doc();
    const uid = ref.id;

    let uploadLogoTask = null;
    let logoUrl = null;
    let uploadLogoLandscapeTask = null;

    let logoUrlLandscape = null;

    if (file) {
      logoUrl = getLogoUrl(uid, file);
      uploadLogoTask = uploadLogo(uid, file);
    
    }
    if(file1){
      logoUrlLandscape = getLogoUrlLandscape(uid, file1);
      uploadLogoLandscapeTask = uploadLandscapeLogo(uid, file1);
    }

    
    const vendorData = { 
      name,
      card,
      logoUrl,
      logoUrlLandscape,
      loyaltyCard,
      priority,
      createdAt: moment(Date()).toDate(),
      vendorCategory: vendorCategory ? JSON.parse(vendorCategory) : ""
    };

    const createVendorsDbTask = createDocument('vendors', uid, vendorData);
    
    try {
      await Promise.all([
        uploadLogoLandscapeTask,
        uploadLogoTask,
        createVendorsDbTask,
      ]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        VENDORS_CREATE_VENDOR_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Vendor created successfully');
    return dispatch(VENDORS_CREATE_VENDOR_SUCCESS({ vendor: vendorData}));
  };
};


export const modifyVendor= ({
  name,
  card,
  loyaltyCard,
  priority,
  file,
  file1,
  id,
  vendorCategory
}) => {
  return async (dispatch, getState) => {
    dispatch(VENDORS_MODIFY_VENDOR_INIT());
    const vendor = getState().vendors.data.find((thisVendor) => thisVendor.id === id);
    const { logoUrl } = vendor;
    const { logoUrlLandscape } = vendor;
    
    let deleteLogoTask;
    let uploadLogoTask;
    let newLogoUrl = null;

    let deleteLogoLandscapeTask;
    let uploadLogoLandscapeTask;
    let newLogoLandscapeUrl = null;

    let logoLandscapeToSend=null;
    let logoToSend=null;

    if (file) {
      newLogoUrl = getLogoUrl(id, file);
      deleteLogoTask = logoUrl && deleteLogo(logoUrl);
      uploadLogoTask = uploadLogo(id, file);
      logoToSend = newLogoUrl;
    }else{
      logoToSend = logoUrl;
    }

    if(file1){
      newLogoLandscapeUrl  = getLogoUrlLandscape(id, file1);
      deleteLogoLandscapeTask = logoUrlLandscape  && deleteLogoLandscape(logoUrlLandscape);
      uploadLogoLandscapeTask = uploadLandscapeLogo(id, file1);
      logoLandscapeToSend = newLogoLandscapeUrl;
    }else{
      logoLandscapeToSend = logoUrlLandscape;
    }
    const vendorData = {
      name,
      card,
      loyaltyCard,
      priority,
      createdAt: moment(Date()).toDate(),
      logoUrl: logoToSend,
      logoUrlLandscape : logoLandscapeToSend,
      vendorCategory: vendorCategory ? JSON.parse(vendorCategory) : ""
    };
    const updateVendorDbTask = updateDocument('vendors', id, vendorData);

    try {
      await Promise.all([deleteLogoTask, deleteLogoLandscapeTask,uploadLogoTask,uploadLogoLandscapeTask, updateVendorDbTask]);
    } catch (error) {
      toastr.error('', String(error));
      return dispatch(
        VENDORS_MODIFY_VENDOR_FAIL({
          error: String(error),
        })
      );
    }

    const { uid } = firebase.auth().currentUser;
    if (id === uid) {
      dispatch(AUTH_UPDATE_VENDOR_DATA({ ...vendorData, id }));
    }
    
    return dispatch(VENDORS_MODIFY_VENDOR_SUCCESS({ vendor: vendorData, id }));
  };
};
export const vendorsCleanUp = () => (dispatch) => dispatch(VENDORS_CLEAN_UP());
