import { createReducer } from 'redux-act';


import {
  COUPONS_FETCH_DATA_INIT,
  COUPONS_FETCH_DATA_SUCCESS,
  COUPONS_FETCH_DATA_FAIL,
  COUPONS_CREATE_COUPON_INIT,
  COUPONS_CREATE_COUPON_SUCCESS,
  COUPONS_CREATE_COUPON_FAIL,
  COUPONS_DELETE_COUPON_FAIL,
  COUPONS_DELETE_COUPON_SUCCESS,
  COUPONS_DELETE_COUPON_INIT,
  COUPONS_MODIFY_COUPON_FAIL,
  COUPONS_MODIFY_COUPON_INIT,
  COUPONS_MODIFY_COUPON_SUCCESS,
  COUPONS_CLEAN_UP
} from 'state/actions/coupons';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};


export const couponsReducer = createReducer(
  {
    [COUPONS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [COUPONS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.data,
      loading: false,
      error: null,
    }),
    [COUPONS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [COUPONS_CREATE_COUPON_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [COUPONS_CREATE_COUPON_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat(payload.coupon),
      loading: false,
      error: null,
      success: true,
    }),
    [COUPONS_CREATE_COUPON_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [COUPONS_DELETE_COUPON_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [COUPONS_DELETE_COUPON_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [COUPONS_DELETE_COUPON_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [COUPONS_MODIFY_COUPON_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [COUPONS_MODIFY_COUPON_SUCCESS]: (state, payload) => ({
      ...state,
      data: !state.data
        ? []
        : state.data.map((elem) => {
          if (elem.id === payload.id) {
            let ret = {
              title: payload.coupon.title,
              // validUntil: payload.coupon.validUntil,
              terms: payload.coupon.terms,
              badge: payload.coupon.badge,
              description: payload.coupon.location,
              // code: payload.coupon.code,
              // qrcode: payload.coupon.qrcode,
              // link: payload.coupon.link,
              // textcode: payload.coupon.textcode,
              days: payload.coupon.days,
              validity: payload.coupon.validity,
              id: payload.id,
              from: payload.coupon.from,
              to: payload.coupon.to,
              vendorId: payload.coupon.vendorId,
              createdAt: payload.coupon.createdAt,
            };
            if (payload.coupon.code) {
              ret = {
                ...ret,
                code: payload.coupon.code
              };
            }
            if (payload.coupon.qrcode) {
              ret = {
                ...ret,
                code: payload.coupon.qrcode
              };
            }
            if (payload.coupon.link) {
              ret = {
                ...ret,
                code: payload.coupon.link
              };
            }
            if (payload.coupon.textcode) {
              ret = {
                ...ret,
                code: payload.coupon.textcode
              };
            }
            return ret;
          }
          return elem;
        }),
      loading: false,
      error: null,
      success: true,
    }),
    [COUPONS_MODIFY_COUPON_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [COUPONS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
  },
  initialState
);
